import React, { Component } from 'react';
import TicketsForReport from '../tickets/TicketsForReport';

class TicketsForReportTable extends Component {
  render() {
    return (
      <div className="content">
        <TicketsForReport />
      </div>
    );
  }
}

export default TicketsForReportTable;