import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createTicket } from '../../actions/ticketActions';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import { getDevicesByClient } from '../../actions/deviceActions';
import classnames from 'classnames';
import Select from 'react-select';

class AddTicketClient extends Component {
  constructor(props) { 
    super(props);
    this.state = {
      device: '',
      priority: '',
      status: '',
      desc: '',
      user: '',
      service: '',
      errors: {},
      selectedDevice: null
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getDevicesByClient({ client: this.props.auth.user.client });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const ticketData = {
      device: this.state.device,
      priority: this.state.priority,
      status: '1',
      desc: this.state.desc,
      user: this.props.auth.user.id,
      service: this.state.service
    };

    //console.log(ticketData.user);

    this.props.createTicket(ticketData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange = selectedDevice => {
    this.setState({ selectedDevice });
    this.setState({
      device: selectedDevice !== null ? selectedDevice.value : ''
    });
    this.setState({
      service: selectedDevice !== null ? selectedDevice.service : ''
    });
  };

  render() {
    const { errors, selectedDevice } = this.state;
    const devices = this.props.device.devices;
    const optionDevices = devices.map(device => ({
      label: device.ibfk + ', ' + device.address + ', ' + device.city,
      service: device.service._id,
      value: device._id
    }));
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Novi tiket"
                category="Kreiraj tiket"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-8">
                        <label className="control-label">Uredjaj</label>
                        <Select
                          defaultValue=""
                          placeholder="Selektuj Uredjaj..."
                          isClearable
                          value={selectedDevice}
                          onChange={this.handleChange}
                          options={optionDevices}
                        />
                        <div className="text-danger">{errors.deviceticket}</div>
                      </div>
                      <div className="col-md-4">
                        <label className="control-label">PRIORITET</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.priority
                          })}
                          name="priority"
                          value={this.state.priority}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Prioritet</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                          <option value="4">Urgent</option>
                        </select>
                        <div className="text-danger">
                          {errors.priorityticket}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">PRIORITET</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.priority
                          })}
                          name="priority"
                          value={this.state.priority}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Prioritet</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                          <option value="4">Urgent</option>
                        </select>
                        <div className="text-danger">
                          {errors.priorityticket}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="control-label">Status</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.status
                          })}
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Status</option>
                          <option value="1">Novi</option>
                          <option value="2">Na cekanju</option>
                          <option value="3">U toku</option>
                          <option value="4">Zavrsen</option>
                          <option value="5">Otkazan</option>
                        </select>
                        <div className="text-danger">{errors.statusticket}</div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis kvara/Komentar"
                          placeholder="Opis kvara/Komentar"
                          name="desc"
                          value={this.state.desc}
                          onChange={this.onChange}
                          error={errors.descticket}
                          rows={5}
                        />
                        <div className="text-info">
                          {this.state.desc.length}/130
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/ticketsclient"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Tikete
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddTicketClient.propTypes = {
  getDevicesByClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  createTicket: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  device: state.device,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createTicket, getDevicesByClient }
)(withRouter(AddTicketClient));
