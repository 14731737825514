import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profileAction';
import { getClients } from '../../actions/clientActions';
import { getServices } from '../../actions/serviceActions';
import { getDevicesByClient } from '../../actions/deviceActions';
import Spinner from '../common/Spinner';
import { Link } from 'react-router-dom';
import { StatsCard } from '../StatsCard/StatsCard';
import DashboardClientTable from './DashboardClientTable';

function count(collection) {
  var totalCount = 0;
  for (var index = 0; index < collection.length; index++) {
    if (index in collection) {
      totalCount++;
    }
  }
  return totalCount;
}
class DashboardClient extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.getCurrentProfile();
    const deviceData = {
      client: this.props.auth.user.client
    };
    this.props.getDevicesByClient(deviceData);
  }
  render() {
    const { user } = this.props.auth;
    const { profile } = this.props.profile;
    const { devices, loading } = this.props.device;
    //let annualCount = 0;
    let ticketsCount = 0;
    let dashboardContent;
    if (profile === null || loading) {
      dashboardContent = <Spinner />;
    } else {
      if (devices.length > 0) {
        //annualCount = devices.map(device => count(device.annualservices));
        ticketsCount = devices.map(device => count(device.ticketlist));
        //const add = (a, b) => a + b;
        const add2 = (a, b) => a + b;
        // use reduce to sum our array
        //annualCount = annualCount.reduce(add);
        ticketsCount = ticketsCount.reduce(add2);
      }
      if (Object.keys(profile).length > 4) {
        dashboardContent = (
          <Container fluid>
            <Row>
              <Col lg={4} sm={6}>
                <StatsCard
                    bigIcon={<i className="pe-7s-usb text-warning" />}
                    statsText="Uredjaji"
                    statsValue={devices.length}
                    statsIcon={<i className="fa fa-refresh" />}
                    statsIconText="Trenutno u bazi"
                  />
              </Col>
              <Col lg={4} sm={6}>
                <Link
                  to="/ticketsclient"
                  style={{ textDecoration: 'none', color: 'black' }}
                  >
                  <StatsCard
                    bigIcon={<i className="pe-7s-tools text-danger" />}
                    statsText="Tiketi"
                    statsValue={ticketsCount}
                    statsIcon={<i className="fa fa-refresh" />}
                    statsIconText="Ukupan broj tiketa"
                  />
                </Link>
              </Col>
              {/*<Col lg={4} sm={6}>
                <StatsCard
                  bigIcon={<i className="pe-7s-graph1 text-info" />}
                  statsText="Tehnicki"
                  statsValue={annualCount}
                  statsIcon={<i className="fa fa-clock-o" />}
                  statsIconText="Ukupno izvrseno"
                />
              </Col>*/}
            </Row>
            <Row>
              <DashboardClientTable device={this.props.device} />
            </Row>
          </Container>
        );
      }
    }
    return <div className="content">{dashboardContent}</div>;
  }
}

DashboardClient.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getDevicesByClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  device: state.device
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, getDevicesByClient }
)(DashboardClient);
