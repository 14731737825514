const serviceRoutes = [
    { path: '/dashboard', name: 'Dashboard', icon: 'pe-7s-graph' },
    { path: '/tickets', name: 'Tiketi', icon: 'pe-7s-note2' },
    //{ path: '/clients', name: 'Klijenti', icon: 'pe-7s-users' },
    { path: '/devices', name: 'Uredjaji', icon: 'pe-7s-usb' },
    //{ path: '/services', name: 'Servisi', icon: 'pe-7s-tools' },
    //{ path: '/profiles', name: 'Admin/Serviseri', icon: 'pe-7s-id' },
    //{ path: '/requests', name: 'Ostali Zahtevi', icon: 'pe-7s-note2' },
    { path: '/edit-profile', name: 'Profil', icon: 'pe-7s-user' },
    //{ path: '/register', name: 'Register', icon: 'pe-7s-add-user' },
    //{ path: '/deleted', name: 'Izbrisani', icon: 'pe-7s-close-circle' },
    { redirect: true, path: '/', to: '/login', name: 'Login' }
  ];
  
  export default serviceRoutes;