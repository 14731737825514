import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Spinner from '../common/Spinner';
import Card from '../../components/Card/Card.jsx';
import Pagination from '../common/Pagination';
import { connect } from 'react-redux';
import { activateClient } from '../../actions/clientActions';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';

class DeletedClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allclients: props.client.deletedclients,
      currentClients: [],
      currentPage: null,
      totalPages: null
    };
  }

  onPageChanged = data => {
    const { allclients } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentClients = allclients.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentClients, totalPages });
  };

  onActivateClientClick(id) {
    confirmAlert({
      title: 'Aktiviraj klijenta',
      message: 'Da li sigurno zelite da aktivirate?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.activateClient(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.deleteTicket(id);
  }
  render() {
    const { deletedclients, loading } = this.props.client;
    const { allclients, currentClients } = this.state;
    let clientItems;
    if (deletedclients === null || loading) {
      clientItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    } else {
      if (deletedclients.length > 0) {
        clientItems = currentClients.map(client => (
          <tr key={client._id}>
            <td>{client.name}</td>
            <td>{client.pib}</td>
            <td>{client.address}</td>
            <td>{client.city}</td>
            <td>{client.phone}</td>
            <td>{client.email}</td>
            <td>
              <button
                onClick={this.onActivateClientClick.bind(this, client._id)}
                className="btn-fill btn btn-sm btn-info"
              >
                Aktiviraj
              </button>
            </td>
          </tr>
        ));
      } else {
        clientItems = (
          <tr>
            <td>Nema izbrisanih klijenata...</td>
          </tr>
        );
      }
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="pull-right" style={{ marginRight: '5px' }}>
                <Pagination
                  totalRecords={allclients.length}
                  pageLimit={10}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
              <Card
                title="Lista izbrisanih klijenata"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Ime</th>
                        <th>Pib</th>
                        <th>Adresa</th>
                        <th>Grad</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Aktiviraj</th>
                      </tr>
                    </thead>
                    <tbody>{clientItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>{' '}
      </div>
    );
  }
}

DeletedClients.propTypes = {
  activateClient: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { activateClient }
)(DeletedClients);
