import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Card from '../../components/Card/Card.jsx';
import { Container, Row, Col } from 'react-bootstrap';
import { Bar, Pie } from 'react-chartjs-2';

class DashboardClientTable extends Component {
  constructor() {
    super();
    this.state = {
      search: ''
    };
  }
  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }

  render() {
    const nextweek = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 7
    );
    const nextmonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 30
    );
    const { loading } = this.props.device;
    const devices = this.props.device.devices.filter(devices => {
      return (
        devices.ibfk.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        devices.address
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        devices.city.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        devices.branch
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    let hiUredjaji = 0;
    let medUredjaji = 0;
    let loUredjaji = 0;
    let devicesItems;
    if (devices === null || loading) {
      devicesItems = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      if (devices.length > 0) {
        devicesItems = devices.map(device => (
          <tr key={device._id}>
            <td>{device.client.name}</td>
            <td>{device.branch}</td>
            <td>{device.address}</td>
            <td>{device.city}</td>
            <td>{device.ibfk}</td>
            <td>
              <Moment format="DD.MM.YYYY">{device.dateoffiscal}</Moment>
            </td>
            <td>
              {isEmpty(device.annualservices) ? null : (
                <Moment format="DD.MM.YYYY">
                  {device.annualservices[0].date}
                </Moment>
              )}
            </td>
            <td>
              {new Date(device.nextannual) < nextweek
                ? ((hiUredjaji += 1),
                  (
                    <p>
                      <span>
                        <Moment format="DD.MM.YYYY">{device.nextannual}</Moment>
                      </span>

                      <span
                        className="btn-fill btn-sm btn-danger"
                        style={{ marginLeft: '3px' }}
                      >
                        High
                      </span>
                    </p>
                  ))
                : new Date(device.nextannual) < nextmonth
                ? ((medUredjaji += 1),
                  (
                    <p>
                      <span>
                        <Moment format="DD.MM.YYYY">{device.nextannual}</Moment>
                      </span>

                      <span
                        className="btn-fill btn-warning btn-sm"
                        style={{ marginLeft: '3px' }}
                      >
                        Medium
                      </span>
                    </p>
                  ))
                : ((loUredjaji += 1),
                  (
                    <p>
                      <span>
                        <Moment format="DD.MM.YYYY">{device.nextannual}</Moment>
                      </span>

                      <span
                        className="btn-fill btn-success btn-sm"
                        style={{ marginLeft: '3px' }}
                      >
                        Low
                      </span>
                    </p>
                  ))}
            </td>
            <td>
              <Link
                to={`/dashboardclient/${device._id}`}
                className="btn-fill btn-sm btn btn-info"
              >
                Info
              </Link>
            </td>
          </tr>
        ));
      } else {
        devicesItems = (
          <tr>
            <td>No devices found...</td>
          </tr>
        );
        console.log(devicesItems)
      }
    }

    loUredjaji = this.props.device.devices.length - hiUredjaji - medUredjaji;
    let labels = [
      'Low: ' + loUredjaji,
      'High: ' + hiUredjaji,
      'Medium: ' + medUredjaji
    ];
    let series = [loUredjaji, hiUredjaji, medUredjaji];
    let labelsBar = [];
    let seriesBar = [];
    //let series2Bar = [];
    this.props.device.devices
      .sort((a, b) => a.ticketlist.length - b.ticketlist.length)
      .reverse()
      .map(device =>
        device.ticketlist.length > 0
          ? (labelsBar.push(device.ibfk + ': ' + device.ticketlist.length),
            seriesBar.push(device.ticketlist.length)): null
            //series2Bar.push(device.annualservices.length))
            // : null je bio ovde 
      );
    const datajs = {
      labels: labelsBar.slice(0, 15),
      datasets: [
        {
          label: 'Broj tiketa po uredjaju',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(51, 153, 255,0.7)',
          borderColor: 'rgba(51, 153, 255,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(51, 153, 255,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(51, 153, 255,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: seriesBar.slice(0, 15)
        }
      ]
    };
    const datajsPie = {
      labels: labels,
      datasets: [
        {
          data: series,
          backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56']
        }
      ]
    };

    return (
      <Container fluid>
        <Row>
          <Col md={8}>
            <Card
              statsIcon="fa fa-history"
              id="chartHours"
              title="Uredjaji po broju tiketa"
              category="Top 15 uredjaja sa najvise kreiranih tiketa"
              stats="Updated now"
              content={
                <div>
                  <Bar
                    ref="chart"
                    data={datajs}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </div>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title="Grafikon - tehnicki pregledi"
              category="Podela po prioritetu tehnickog pregleda"
              statsIcon="fa fa-clock-o"
              stats="Trenutno stanje"
              content={
                <div>
                  <Pie
                    data={datajsPie}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DashboardClientTable;

