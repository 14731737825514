import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getProfileById, passwordResetID, createProfile } from '../../actions/profileAction';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import { Card } from '../../components/Card/Card';
import { confirmAlert } from 'react-confirm-alert';

class clientUserItem extends Component {
  constructor() {
    super();
    this.state = {
      phone: '',
      branch: '',
      service: '',
      password: '',
      password2: '',
      email: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordReset = this.onPasswordReset.bind(this);
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile) {
      const profile = nextProps.profile.profile;

      profile.phone = !isEmpty(profile.phone) ? profile.phone : '';
      profile.name = !isEmpty(profile.name) ? profile.name : '';
      profile.email = !isEmpty(profile.email) ? profile.email : '';
      profile.client = !isEmpty(profile.client) ? profile.client : '';
      profile.password = !isEmpty(profile.password) ? profile.password : '';
      profile.password2 = !isEmpty(profile.password2) ? profile.password2 : '';

      // Set component fields state
      this.setState({
        phone: profile.phone,
        branch: profile.branch,
        service: profile.service,
        email: profile.email,
        name: profile.name,
        client: profile.client.name
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const profileData = {
      phone: this.state.phone,
      branch: this.state.branch,
      service: this.state.service,
      email: this.state.email
    };

    this.props.createProfile(profileData, this.props.history);
  }

  onPasswordReset(e) {
    e.preventDefault();
    const profileData2 = {
      email: this.state.email,
      service: this.state.service,
      password: this.state.password,
      password2: this.state.password2
    };
    confirmAlert({
      title: 'Resetuj lozinku',
      message: 'Da li sigurno zelite da resetujete?',
      buttons: [
        {
          label: 'Da',
          onClick: () =>
            this.props.passwordResetID(profileData2, this.props.history)
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.passwordReset(profileData2, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getProfileById(this.props.match.params.id);
    }
  }

  render() {
    const { errors } = this.state;
    let profileContent;

    profileContent = (
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title={this.state.name}
                category="Podaci o korisniku"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Ime korisnika"
                          placeholder="Ime korisnika"
                          name="name"
                          value={this.state.name}
                          error={errors.name}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Email adresa"
                          placeholder="Email Address"
                          name="email"
                          type="email"
                          value={this.state.email}
                          error={errors.email}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Ime klijenta"
                          placeholder="Ime klijenta"
                          name="client"
                          value={this.state.client}
                          error={errors.client}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon korisnika"
                          placeholder="Telefon korisnika"
                          name="phone"
                          value={this.state.phone}
                          error={errors.phone}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      
                      <div className="col-md-6">
                        <Link
                          to="/clientusers"
                          className="btn-fill col-md-12 pull-center btn btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na korisnike
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                title="Password Reset"
                category="* Da biste resetovali lozinku potrebno je uneti staru lozinku"
                content={
                  <form onSubmit={this.onPasswordReset}>
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Nova lozinka"
                          name="password"
                          type="password"
                          value={this.state.password}
                          onChange={this.onChange}
                          error={errors.password2}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Ponovi lozinku"
                          name="password2"
                          type="password"
                          value={this.state.password2}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="Reset"
                      className="btn-fill pull-right btn btn-danger"
                    />
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      );
    
    return <div className="content">{profileContent}</div>;
 }
}

clientUserItem.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  passwordResetID: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getProfileById, passwordResetID, createProfile }
)(clientUserItem);
