import axios from 'axios';
import {
  GET_ERRORS,
} from './types';

// Prebacivanje izTT u ticketing
export const createNew = newClient => dispatch => {
  axios
    .post('/api/transfer/addClientFromTechTool/', newClient)
    .then(res => {
      console.log(res.data);
      alert('Ubacen ')
    })
    .catch(err => {
      alert(err.response.data.error)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
}