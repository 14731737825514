import { Container, Row, Col, Table } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import Moment from 'react-moment';
import { getDevices, deleteDevice } from '../../actions/deviceActions';
import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card.jsx';
import SelectListGroup from '../common/SelectListGroup';
import { confirmAlert } from 'react-confirm-alert';
import ReactToExcel from 'react-html-table-to-excel';
import DashboardTable from './DashboardTable';

class DeviceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };

    this.options = {
      defaultSortName: 'nextannual',
      defaultSortOrder: 'asc'
    };
  }
  onDeleteClick(id) {
    confirmAlert({
      title: 'Izbrisi uredjaj',
      message: 'Da li sigurno zelite da izbrisete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => ((this.props.deleteDevice(id), window.location.reload()))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }

  componentDidMount() {
    this.props.getDevices();
  }

  render() {
    //new Date(devices.nextannual) < nextweek
    const { loading } = this.props.device;
    //Select options for Service
    let styledelete;
    console.log(styledelete);
    if (this.props.auth.user.position === 'Admin') {
      styledelete = { width: '80px' };
    } else {
      styledelete = { width: '80px', visibility: 'hidden' };
    }

    const devices = this.props.device.devices.filter(devices => {
      return (
        devices.ibfk
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        devices.client.name
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        devices.address
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        devices.service.name
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 
        //devices.branch
        //  .toLowerCase()
        //  .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    const optionsService = Array.from(
      new Set(this.props.device.devices.map(s => s.service.name))
    ).map(name => ({
      label: name,
      value: name
    }));
    optionsService.unshift({ label: '* Selektujte servis', value: '' });

    let devicesItems;
    if (devices === null || loading) {
      devicesItems = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      if (devices.length > 0 && this.state.search.length > 6) //&& this.state.search.length > 1 
      {
        devicesItems = devices.map(device => (
          <tr key={device._id}>
            <td>{device.client.name}</td>
            <td>
              {device.address}, {device.city}
            </td>
            <td>{device.service.name}</td>
            <td>{device.ibfk}</td>
            <td>
              <Moment format="DD.MM.YYYY">{device.dateoffiscal}</Moment>
            </td>
           
            <td>
              <div className="btn-group btn-group">
                <Link
                  to={`/devices/${device._id}`}
                  style={{ marginRight: '3px', width: '80px' }}
                  className="btn-fill btn btn-sm btn-info"
                >
                  Info
                </Link>
                <button
                  type="button"
                  hidden
                  className="btn-fill btn btn-sm btn-danger"
                  //style={styledelete}
                  onClick={this.onDeleteClick.bind(this, device._id)}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        ));
      } else {
        devicesItems = (
          <tr>
            <td>No devices found...</td>
          </tr>
        );
      }
    }

    return (
      <Container fluid>
        <Link
          to="/devices/add/add-device"
          className="btn btn-fill btn-info btn-sm"
          style={{ marginBottom: '5px' }}
        >
          <i className="pe-7s-plus" /> Novi Uredjaj
        </Link>

        <div className="row">
          <div className="col-md-3">
            <SelectListGroup
              placeholder="Service"
              name="service"
              value={this.state.search}
              onChange={this.updateSearch.bind(this)}
              options={optionsService}
            />
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <label className="control-label" />
              <input
                className="form-control form-control-lg"
                type="text"
                name="search"
                placeholder="Pretraga uredjaja..."
                value={this.state.search}
                onChange={this.updateSearch.bind(this)}
                //onKeyPress={this.updateSearch.bind(this)}
                style={{ marginBottom: '5px' }}
              />
            </div>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <div
              className="pull-right"
              style={{ marginRight: '5px', marginTop: '5px' }}
            >
              
            </div>
            <Card
              title="Informacije o fiskalnim uredjajima"
              category="Lista uredjaja"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <Table striped hover id="table-to-xls">
                    <thead>
                      <tr>
                        <th>Klijent</th>
                        {/*<th>Ogranak</th>*/}
                        <th>Adresa</th>
                        <th>Servis</th>
                        <th>SBU</th>
                        <th>Fiskalizacija</th>
                        {/*<th>Poslednji tehnicki</th>
                        <th>Naredni tehnicki</th>*/}
                        {/*<th>Vendor</th>*/}
                        <th />
                      </tr>
                    </thead>
                    <tbody>{devicesItems}</tbody>
                  </Table>
                </div>
              }
            />
          </Col>
        </Row>
        <Row>
        <ReactToExcel
                font="TimesNewRoman"
                className="btn btn-fill btn-info btn-sm"
                table="table-to-xls"
                filename={'Uredjaji_GalebTicketing_' + new Date().toISOString()}
                sheet="sheet 1"
                buttonText="Izvoz u .xls"
              />
        </Row>
        <Row style={{padding : '20px'}}>
          <DashboardTable device={this.props.device} />
        </Row>
      </Container>
    );
  }
}

DeviceTable.propTypes = {
  deleteDevice: PropTypes.func.isRequired,
  getDevices: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  device: state.device,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getDevices, deleteDevice }
)(DeviceTable);
