import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { getRequestById, updateRequest } from '../../actions/requestAction';
import { getClients } from '../../actions/clientActions';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import Moment from 'react-moment';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card';
import classnames from 'classnames';
import SelectListGroup from '../common/SelectListGroup';

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      company: '',
      device: '',
      priority: '',
      status: '',
      desc: '',
      address: '',
      city: '',
      phone: '',
      mail: '',
      parts: '',
      requesttype: '',
      datecreate: '',
      dateend: '',
      user: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newReq = {
      priority: this.state.priority,
      status: this.state.status,
      desc: this.state.desc,
      parts: this.state.parts,
      requesttype: this.state.requesttype,
      client: this.state.client,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone,
      mail: this.state.mail,
      device: this.state.device
    };

    this.props.updateRequest(this.state._id, newReq, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  UNSAFE_componentWillMount() {
    if (this.props.match.params._id) {
      this.props.getRequestById(this.props.match.params._id);
    }
  }

  componentDidMount() {
    this.props.getClients();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.request.request) {
      const request = nextProps.request.request;

      request._id = !isEmpty(request._id) ? request._id : '';
      request.client = !isEmpty(request.client) ? request.client : '';
      request.device = !isEmpty(request.device) ? request.device : '';
      request.priority = !isEmpty(request.priority) ? request.priority : '';
      request.status = !isEmpty(request.status) ? request.status : '';
      request.desc = !isEmpty(request.desc) ? request.desc : '';
      request.parts = !isEmpty(request.parts) ? request.parts : '';
      request.address = !isEmpty(request.address) ? request.address : '';
      request.city = !isEmpty(request.city) ? request.city : '';
      request.phone = !isEmpty(request.phone) ? request.phone : '';
      request.mail = !isEmpty(request.mail) ? request.mail : '';
      request.requesttype = !isEmpty(request.requesttype)
        ? request.requesttype
        : '';
      request.datecreate = !isEmpty(request.datecreate)
        ? request.datecreate
        : '';
      request.dateend = !isEmpty(request.dateend) ? request.dateend : '';
      request.user = !isEmpty(request.user) ? request.user : '';

      this.setState({
        _id: request._id,
        client: !isEmpty(request.client) ? request.client._id : '',
        device: request.device,
        priority: request.priority,
        status: request.status,
        desc: request.desc,
        parts: request.parts,
        address: request.address,
        city: request.city,
        phone: request.phone,
        mail: request.mail,
        requesttype: request.requesttype,
        datecreate: request.datecreate,
        dateend: request.dateend
      });
    }
  }

  render() {
    const { request, loading } = this.props.request;
    const { clients } = this.props.client;
    const optionsClient = clients.map(client => ({
      label: client.name,
      value: client._id
    }));
    optionsClient.unshift({ label: '* Selektujte klijenta', value: '' });
    let requestContent;
    if (request === null || loading) {
      requestContent = <Spinner />;
    } else {
      requestContent = (
        <Container fluid>
          <Row>
            <Col md={10}>
              <Card
                title="Informacije o zahtevu"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <SelectListGroup
                          labels="Klijent"
                          placeholder="Klijent"
                          name="client"
                          value={this.state.client}
                          options={optionsClient}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Uredjaj"
                          placeholder="Uredjaj"
                          name="device"
                          value={this.state.device}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">PRIORITET</label>
                        <select
                          name="priority"
                          value={this.state.priority}
                          onChange={this.onChange}
                          className={classnames('form-control', {
                            'is-invalid': ''
                          })}
                        >
                          <option value="">* Selektuj Prioritet</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                          <option value="4">Urgent</option>
                        </select>
                        <div className="text-danger" />
                      </div>
                      <div className="col-md-6">
                        <label className="control-label">Status</label>
                        <select
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                          className={classnames('form-control', {
                            'is-invalid': ''
                          })}
                        >
                          <option value="">* Selektuj Status</option>
                          <option value="1">Novi</option>
                          <option value="2">Na cekanju</option>
                          <option value="3">U toku</option>
                          <option value="4">Zavrsen</option>
                          <option value="5">Otkazan</option>
                        </select>
                        <div className="text-danger" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Adresa"
                          placeholder="Adresa"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon"
                          placeholder="Telefon"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Mail"
                          placeholder="Mail"
                          name="mail"
                          value={this.state.mail}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis kvara/Komentar"
                          placeholder="Opis kvara/Komentar"
                          name="desc"
                          value={this.state.desc}
                          onChange={this.onChange}
                          rows={5}
                        />
                        <div className="text-info">
                          {this.state.desc.length}/130
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis popravke/Komentar"
                          placeholder="Opis popravke/Komentar"
                          name="parts"
                          value={this.state.parts}
                          onChange={this.onChange}
                          rows={5}
                        />
                        <div className="text-info">
                          {this.state.parts.length}/130
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="control-label">Tip Zahteva</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': ''
                          })}
                          name="requesttype"
                          value={this.state.requesttype}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Tip</option>
                          <option value="Kvar">Kvar</option>
                          <option value="Promena adrese">Promena adrese</option>
                          <option value="Dodati uredjaj">Dodati uredjaj</option>
                          <option value="Zakazi tehnicki">
                            Zakazi tehnicki
                          </option>
                          <option value="Ostalo">Ostalo</option>
                        </select>
                        <div className="text-danger" />
                        {/* <TextFieldGroup
                          label="Tip Zahteva"
                          placeholder="Tip Zahteva"
                          name="requesttype"
                          value={this.state.requesttype}
                          onChange={this.onChange}
                        /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Datum kreiranja:{' '}
                          {isEmpty(this.state.datecreate) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY HH:mm">
                                {this.state.datecreate}
                              </Moment>
                            </span>
                          )}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Tiket kreirao/la:{' '}
                          {isEmpty(this.state.datecreate) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY HH:mm">
                                {this.state.datecreate}
                              </Moment>{' '}
                              {isEmpty(request.user.name)
                                ? null
                                : request.user.name}
                            </span>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/requests"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Zahteve
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      );
    }
    return <div className="content">{requestContent}</div>;
  }
}

Request.propTypes = {
  getClients: PropTypes.func.isRequired,
  getRequestById: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  request: state.request,
  client: state.client
});

export default connect(
  mapStateToProps,
  { getRequestById, getClients, updateRequest }
)(withRouter(Request));
