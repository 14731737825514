import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createNew } from '../../actions/techtoolActions';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import { getServices } from '../../actions/serviceActions';
import SelectListGroup from '../common/SelectListGroup';
const Validator = require('validator');

class addClientFromTechTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      JSONstring: '',
      _id: '',
      compName: '',
      compVatId: '',
      compId: '',
      compAddrStreet: '',
      compAddrNumber: '',
      compAddrZip: '',
      compAddrCity: '',
      compPhone: '',
      compEmail: '',
      userFirstName: '',
      userLastName: '',
      userPhone: '',
      userEmail: '',
      deviceSerialNo: '',
      deviceAddress: '',
      deviceAddrZip: '',
      deviceAddrCity: '',
      deviceVendor: '',
      vendorName: '',
      service: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    //
    document.getElementById("btn").disabled = true;
    setTimeout(function () { document.getElementById("btn").disabled = false; }, 1000);
    //
    const newClient = {
      _id: this.state._id.trim(),
      compName: Validator.stripLow(this.state.compName.trim()),
      compVatId: Validator.stripLow(this.state.compVatId.trim()),
      compId: Validator.stripLow(this.state.compId.trim()),
      compAddrStreet: Validator.stripLow(this.state.compAddrStreet.trim()),
      compAddrNumber: Validator.stripLow(this.state.compAddrNumber.trim()),
      compAddrZip: this.state.compAddrZip,
      compAddrCity: this.state.compAddrCity,
      compPhone: Validator.stripLow(this.state.compPhone.trim()),
      compEmail: Validator.normalizeEmail(this.state.compEmail.trim(), { gmail_remove_dots: false }),
      userFirstName: Validator.stripLow(this.state.userFirstName.trim()),
      userLastName: Validator.stripLow(this.state.userLastName.trim()),
      userPhone: Validator.stripLow(this.state.userPhone.trim()),
      userEmail: Validator.normalizeEmail(this.state.userEmail.trim(), { gmail_remove_dots: false }),
      deviceSerialNo: Validator.stripLow(this.state.deviceSerialNo.toUpperCase().trim()),
      deviceAddress: Validator.stripLow(this.state.deviceAddress.trim()),
      service: this.state.service.trim(),
      deviceAddrZip: this.state.deviceAddrZip,
      deviceAddrCity: this.state.deviceAddrCity,
    };
    console.log(newClient);
    this.props.createNew(newClient);
  }

  getData() {
    const data = this.state.JSONstring;
    const dataStringified = JSON.parse(data)

    this.setState({
      _id: dataStringified._id,
      compName: dataStringified.compName,
      compVatId: dataStringified.compVatId,
      compId: dataStringified.compId,
      compAddrStreet: dataStringified.compAddrStreet,
      compAddrNumber: dataStringified.compAddrNumber,
      compAddrZip: dataStringified.compAddrZip,
      compAddrCity: dataStringified.compAddrCity,
      compEmail: dataStringified.compEmail,
      userFirstName: dataStringified.userFirstName,
      userLastName: dataStringified.userLastName,
      userPhone: dataStringified.userPhone,
      userEmail: dataStringified.userEmail,
      deviceSerialNo: dataStringified.deviceSerialNo,
      deviceAddress: dataStringified.deviceAddress,
      deviceAddrZip: dataStringified.deviceAddrZip,
      deviceAddrCity: dataStringified.deviceAddrCity,
    })

  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() { 
    this.props.getServices();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    const { services } = this.props.service;
    
    const optionsService = services.map(service => ({
      label: service.name,
      value: service._id
    }));
    optionsService.unshift({ label: '* Selektujte servis', value: '' });

    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card title="Podaci iz TT"
                content={
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="String iz TT"
                          placeholder="String iz TT"
                          name="JSONstring"
                          value={this.state.JSONstring}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <input
                          type='button'
                          label='Izvuci podatke'
                          value="izvuci podatke"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          onClick={this.getData.bind(this)}
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </div>
                  </div>
                } />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card
                title="Podaci o preduzeću"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Naziv preduzeća *"
                          placeholder="Naziv Preduzeća"
                          name="compName"
                          value={this.state.compName}
                          onChange={this.onChange}
                          error={errors.compName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="PIB *"
                          placeholder="PIB"
                          name="compVatId"
                          value={this.state.compVatId}
                          onChange={this.onChange}
                          error={errors.compVatId}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Matični broj *"
                          placeholder="Matični broj"
                          name="compId"
                          value={this.state.compId}
                          onChange={this.onChange}
                          error={errors.compId}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <TextFieldGroup
                          label="Adresa *"
                          placeholder="Adresa"
                          name="compAddrStreet"
                          value={this.state.compAddrStreet}
                          onChange={this.onChange}
                          error={errors.compAddrStreet}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Broj *"
                          placeholder="Broj"
                          name="compAddrNumber"
                          value={this.state.compAddrNumber}
                          onChange={this.onChange}
                          error={errors.compAddrNumber}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          labels="Poštanski broj *"
                          placeholder="Poštanski broj"
                          name="compAddrZip"
                          value={this.state.compAddrZip}
                          onChange={this.onChange}
                          error={errors.compAddrZip}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="compAddrCity"
                          value={this.state.compAddrCity}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon"
                          placeholder="Telefon"
                          name="compPhone"
                          value={this.state.compPhone}
                          onChange={this.onChange}
                          error={errors.compPhone}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="E-mail adresa preduzeća *"
                          placeholder="E-mail adresa preduzeća"
                          name="compEmail"
                          type="compEmail"
                          value={this.state.compEmail}
                          onChange={this.onChange}
                          error={errors.compEmail}
                        />
                      </div>
                    </div>
                  </form>
                }
              />
              <Card
                title="Podaci o kontakt osobi"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Ime kontakt osobe *"
                          placeholder="Ime kontakt osobe"
                          name="userFirstName"
                          value={this.state.userFirstName}
                          onChange={this.onChange}
                          error={errors.userFirstName}
                        />
                      </div>
                      <div className="col-md-8">
                        <TextFieldGroup
                          label="Prezime kontakt osobe *"
                          placeholder="Prezime kontakt osobe"
                          name="userLastName"
                          value={this.state.userLastName}
                          onChange={this.onChange}
                          error={errors.userLastName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon kontakt osobe"
                          placeholder="Telefon kontakt osobe"
                          name="userPhone"
                          value={this.state.userPhone}
                          onChange={this.onChange}
                          error={errors.userPhone}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="E-mail adresa kontakt osobe *"
                          placeholder="E-mail adresa kontakt osobe"
                          name="userEmail"
                          type="userEmail"
                          value={this.state.userEmail}
                          onChange={this.onChange}
                          error={errors.userEmail}
                        />
                      </div>
                    </div>
                  </form>
                }
              />
              <Card
                title="Podaci o uređaju"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                    <div className="col-md-4">
                        <SelectListGroup
                          labels="Servis"
                          placeholder="Service"
                          name="service"
                          value={this.state.service}
                          onChange={this.onChange}
                          options={optionsService}
                          error={errors.service}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Serijski broj uređaja *"
                          placeholder="Serijski broj uređaja"
                          name="deviceSerialNo"
                          value={this.state.deviceSerialNo}
                          onChange={this.onChange}
                          error={errors.deviceSerialNo}
                        />
                      </div>
                      <div className="col-md-8">
                        <TextFieldGroup
                          label="Adresa lokacije na kojoj se uređaj nalazi *"
                          placeholder="Adresa lokacije na kojoj se uređaj nalazi"
                          name="deviceAddress"
                          value={this.state.deviceAddress}
                          onChange={this.onChange}
                          error={errors.deviceAddress}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          labels="Poštanski broj *"
                          placeholder="Poštanski broj"
                          name="deviceAddrZip"
                          value={this.state.deviceAddrZip}
                          onChange={this.onChange}
                          error={errors.deviceAddrZip}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="deviceAddrCity"
                          value={this.state.deviceAddrCity}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          //
                          id="btn"
                          //
                          type="submit"
                          value="Napravi uredjaj i klijenta "
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </div>
                    <div className="stats" >* su označena obavezna polja</div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

addClientFromTechTool.propTypes = {
  getServices: PropTypes.func.isRequired,
  createNew: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  service: state.service,
  auth: state.auth,
  client: state.client,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { createNew, getServices }
)(withRouter(addClientFromTechTool));