import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getClients, deleteClient, getClientSearch } from '../../actions/clientActions';
import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { confirmAlert } from 'react-confirm-alert';

class ClientTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
  }

  onDeleteClick(id) {
    confirmAlert({
      title: 'Izbrisi klijenta',
      message: 'Da li sigurno zelite da izbrisete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => ((this.props.deleteClient(id), window.location.reload()))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  updateSearch = e => {
    if (e.key === 'Enter'){
      this.setState({ search: e.target.value
      }, () => {this.props.getClientSearch(this.state.search); }
      );
    }
  }

  

  //componentDidMount() {
  //  this.props.getClients();
  //}

  render() {
    const { loading } = this.props.client;

    const clientSearch = this.props.client.clientSearch;
    /*.filter(clients => {
      return (
        clients.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        clients.pib.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 //||
        //clients.address.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      );
    });*/

    let clientItems;
    if (clientSearch === null || loading) {
      clientItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    } else {
      if (clientSearch.length > 0 && this.state.search.length > 4) {
        clientItems = clientSearch.map(client => (
          <tr key={client._id}>
            <td>{client.name}</td>
            <td>{client.pib}</td>
            <td>{client.address}</td>
            <td>{client.city}</td>
            <td>{client.phone}</td>
            <td>{client.email}</td>
            <td>
              <div className="btn-group mb-4">
                <Link
                  to={`/clients/${client._id}`}
                  className="btn-fill btn btn-sm btn-info"
                >
                  Info
                </Link>
              </div>
            </td>
          </tr>
        ));
      } else {
        clientItems = (
          <tr>
            <td>No clients found...</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <Container fluid>
          <Link
            to="/clients/add/add-client"
            className="btn btn-fill btn-sm btn-info"
            style={{ marginBottom: '5px' }}
          >
            <i className="pe-7s-plus" /> Novi Klijent
          </Link>
          <input
            className="form-control form-control-lg col-md-4"
            type="text"
            name="search"
            placeholder="Pretraga klijenata..."
            //value={this.state.search}
            onKeyUp={this.updateSearch.bind(this)}
            style={{ marginBottom: '5px' }}
          />
          <Row>
            <Col md={12}>
              <Card
                title="Klijenti"
                category="Informacije o klijentima"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Ime</th>
                        <th>Pib</th>
                        <th>Adresa</th>
                        <th>Grad</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{clientItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ClientTable.propTypes = {
  deleteClient: PropTypes.func.isRequired,
  getClientSearch: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  clientSearch: state.clientSearch
});

export default connect(
  mapStateToProps,
  { getClients, deleteClient, getClientSearch }
)(ClientTable);
