import React, { Component } from 'react';
import { NavItem, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileAction';

class HeaderLinks extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;

    // const notification = (
    //   <div>
    //     <i className="fa fa-globe" />
    //     <b className="caret" />
    //     <span className="notification">5</span>
    //     <p className="hidden-lg hidden-md">Notification</p>
    //   </div>
    // );

    const authLinks =
      this.props.auth.user.position !== 'Klijent' ? (
        <div>
          <Nav pullRight>
            <NavItem eventKey={1} href="/dashboard">
              <i className="fa fa-dashboard" />
            </NavItem>
            {/* <NavDropdown
            eventKey={2}
            title={notification}
            noCaret
            id="basic-nav-dropdown"
          >
            <MenuItem eventKey={2.1}>Notification 1</MenuItem>
            <MenuItem eventKey={2.2}>Notification 2</MenuItem>
            <MenuItem eventKey={2.3}>Notification 3</MenuItem>
            <MenuItem eventKey={2.4}>Notification 4</MenuItem>
            <MenuItem eventKey={2.5}>Another notifications</MenuItem>
          </NavDropdown> */}
          </Nav>
          <Nav pullRight>
            <NavItem eventKey={1} style={{ textDecoration: 'none' }} href="/edit-profile">
              {user.name}
            </NavItem>
            <NavItem
              eventKey={3}
              style={{ textDecoration: 'none' }}
              href="/"
              onClick={this.onLogoutClick.bind(this)}
            >
              Log out
            </NavItem>
          </Nav>
        </div>
      ) : (
        <div>
          <Nav pullRight>
            <NavItem eventKey={1} href="/dashboardclient">
              <i className="fa fa-dashboard" />
            </NavItem>
            {/* <NavDropdown
        eventKey={2}
        title={notification}
        noCaret
        id="basic-nav-dropdown"
      >
        <MenuItem eventKey={2.1}>Notification 1</MenuItem>
        <MenuItem eventKey={2.2}>Notification 2</MenuItem>
        <MenuItem eventKey={2.3}>Notification 3</MenuItem>
        <MenuItem eventKey={2.4}>Notification 4</MenuItem>
        <MenuItem eventKey={2.5}>Another notifications</MenuItem>
      </NavDropdown> */}
          </Nav>
          <Nav pullRight>
            <NavItem eventKey={1} style={{ textDecoration: 'none' }} href="/profile-client">
              {user.name}
            </NavItem>
            <NavItem
              eventKey={3}
              href="/"
              onClick={this.onLogoutClick.bind(this)}
            >
              Log out
            </NavItem>
          </Nav>
        </div>
      );

    const guestLinks = (
      <div>
        <Nav pullRight>
          <NavItem eventKey={1} href="/login">
            Login
          </NavItem>
        </Nav>
      </div>
    );

    return <div>{isAuthenticated ? authLinks : guestLinks}</div>;
  }
}
HeaderLinks.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser, clearCurrentProfile }
)(HeaderLinks);
