import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Spinner from '../common/Spinner';
import Card from '../../components/Card/Card.jsx';
import Pagination from '../common/Pagination';
import { connect } from 'react-redux';
import { activateService } from '../../actions/serviceActions';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';

class DeletedServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allservices: props.service.deletedservices,
      currentServices: [],
      currentPage: null,
      totalPages: null
    };
  }

  onPageChanged = data => {
    const { allservices } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentServices = allservices.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentServices, totalPages });
  };

  onActivateServiceClick(id) {
    confirmAlert({
      title: 'Aktiviraj servis',
      message: 'Da li sigurno zelite da aktivirate?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.activateService(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.deleteTicket(id);
  }

  render() {
    const { deletedservices, loading } = this.props.service;
    const { allservices, currentServices } = this.state;
    let serviceItems;
    if (deletedservices === null || loading) {
      serviceItems = (
        <tr>
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
        </tr>
      );
    } else {
      if (deletedservices.length > 0) {
        serviceItems = currentServices.map(service => (
          <tr key={service._id}>
            <td>{service.name}</td>
            <td>{service.address}</td>
            <td>{service.city}</td>
            <td>{service.phone}</td>
            <td>{service.email}</td>
            <td>
              <button
                onClick={this.onActivateServiceClick.bind(this, service._id)}
                className="btn-fill btn btn-sm btn-info"
              >
                Aktiviraj
              </button>
            </td>
          </tr>
        ));
      } else {
        serviceItems = (
          <tr>
            <td>Nema izbrisanih servisa...</td>
          </tr>
        );
      }
    }

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="pull-right" style={{ marginRight: '5px' }}>
                <Pagination
                  totalRecords={allservices.length}
                  pageLimit={10}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
              <Card
                title="Lista izbrisanih servisa"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Ime</th>
                        <th>Adresa</th>
                        <th>Grad</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Aktiviraj</th>
                      </tr>
                    </thead>
                    <tbody>{serviceItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

DeletedServices.propTypes = {
  activateService: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { activateService }
)(DeletedServices);
