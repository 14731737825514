import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getReport } from '../../actions/ticketActions';
import Card from '../Card/Card';
import TextFieldGroup from '../common/TextFieldGroup';
import { getServices } from '../../actions/serviceActions';
import { getProfilesAdmin } from '../../actions/profileAction';

class TicketTable extends Component {
  constructor() {
    super();
    this.state = {
      search: '',
      createDate: '',
      endDate: '',
      createDateS: '',
      endDateS: '',
      servicer: '',
      service: '',
      servicerId: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.getReport(this.state.createDate, this.state.endDate);
  }

  componentDidMount() {
    this.props.getProfilesAdmin();
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col md={6}>
            <Card
              title="Izveštaj"
              content={
                <form onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                        <TextFieldGroup
                          label="Datum od"
                          type="date"
                          placeholder="YYYY-MM-DD"
                          name="createDate"
                          value={this.state.createDate}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Datum do "
                          type="date"
                          placeholder="YYYY-MM-DD"
                          name="endDate"
                          value={this.state.endDate}
                          onChange={this.onChange}
                        />
                    </div>
                  </div>
                  <input
                    type="submit"
                    value="Pretraži"
                    className="btn-fill col-md-2 pull-center btn btn-info"
                    style={{ margin: '3px' }}
                  />
                </form>
              } />
          </Col>
        </Row>{' '}
      </Container>
    );
  }
}

TicketTable.propTypes = {
  getProfilesAdmin: PropTypes.func.isRequired,
  profiles: PropTypes.object.isRequired,
  getReport: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profiles: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getReport, getServices, getProfilesAdmin }
)(TicketTable);
