import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRequest } from '../../actions/requestAction';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import { getClients } from '../../actions/clientActions';
import classnames from 'classnames';
import Select from 'react-select';

class AddRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      company: '',
      device: '',
      priority: '',
      status: '',
      desc: '',
      address: '',
      city: '',
      phone: '',
      mail: '',
      requesttype: '',
      errors: {},
      selectedClient: null
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.user.position === 'Klijent') {
      this.props.history.push('/dashboardclient');
    } else {
      this.props.getClients();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const requestData = {
      client: this.state.client,
      company: this.state.company,
      device: this.state.device,
      priority: this.state.priority,
      status: this.state.status,
      desc: this.state.desc,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone,
      mail: this.state.mail,
      requesttype: this.state.requesttype
    };
    this.props.createRequest(requestData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeClient = selectedClient => {
    this.setState({ selectedClient });
    this.setState({
      client: selectedClient !== null ? selectedClient.value : ''
    });
  };

  render() {
    const { errors } = this.state;
    const { selectedClient } = this.state;
    const { clients } = this.props.client;
    const optionsClient = clients.map(client => ({
      label: client.name,
      value: client._id
    }));
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={10}>
              <Card
                title="Novi zahtev"
                category="Kreiraj zahtev"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">
                          Klijent (Nije obavezno)
                        </label>
                        <Select
                          defaultValue=""
                          placeholder="Selektuj Klijenta..."
                          isClearable
                          value={selectedClient}
                          onChange={this.handleChangeClient}
                          options={optionsClient}
                        />
                        <div className="text-danger">
                          {errors.clientrequest}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Uredjaj"
                          placeholder="Uredjaj"
                          name="device"
                          value={this.state.device}
                          onChange={this.onChange}
                          error={errors.devicerequest}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">PRIORITET</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.priorityrequest
                          })}
                          name="priority"
                          value={this.state.priority}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Prioritet</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                          <option value="4">Urgent</option>
                        </select>
                        <div className="text-danger">
                          {errors.priorityrequest}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="control-label">Status</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.statusrequest
                          })}
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Status</option>
                          <option value="1">Novi</option>
                          <option value="2">Na cekanju</option>
                          <option value="3">U toku</option>
                          <option value="4">Zavrsen</option>
                          <option value="5">Otkazan</option>
                        </select>
                        <div className="text-danger">
                          {errors.statusrequest}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Adresa"
                          placeholder="Adresa"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                          error={errors.addressrequest}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                          error={errors.cityrequest}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon"
                          placeholder="Telefon"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          error={errors.phonerequest}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Mail"
                          placeholder="Mail"
                          name="mail"
                          value={this.state.mail}
                          onChange={this.onChange}
                          error={errors.mailrequest}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis kvara/Komentar"
                          placeholder="Opis kvara/Komentar"
                          name="desc"
                          value={this.state.desc}
                          onChange={this.onChange}
                          error={errors.descrequest}
                          rows={5}
                        />
                        <div className="text-info">
                          {this.state.desc.length}/130
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="control-label">Tip Zahteva</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.requesttype
                          })}
                          name="requesttype"
                          value={this.state.requesttype}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Tip</option>
                          <option value="Kvar">Kvar</option>
                          <option value="Promena adrese">Promena adrese</option>
                          <option value="Dodati uredjaj">Dodati uredjaj</option>
                          <option value="Zakazi tehnicki">
                            Zakazi tehnicki
                          </option>
                          <option value="Ostalo">Ostalo</option>
                        </select>
                        <div className="text-danger">{errors.requesttype}</div>
                        {/* <TextFieldGroup
                          label="Tip Zahteva"
                          placeholder="Tip Zahteva"
                          name="requesttype"
                          value={this.state.requesttype}
                          onChange={this.onChange}
                          error={errors.requesttype}
                        /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/requests"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Zahteve
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddRequest.propTypes = {
  getClients: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { createRequest, getClients }
)(withRouter(AddRequest));
