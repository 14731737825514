import axios from 'axios';
import {
  ADD_CLIENT,
  GET_ERRORS,
  GET_CLIENTS,
  CLIENTS_LOADING,
  DELETE_CLIENT,
  GET_CLIENT,
  GET_DELETED_CLIENTS,
  GET_CLIENTS_NAI,
  GET_CLIENTS_COUNT,
  GET_SEARCH_CLIENT
} from './types';

// Create Client
export const createClient = (clientData, history) => dispatch => {
  axios
    .post('/api/clients', clientData)
    .then(res => history.push('/clients'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//search clients 
export const getClientSearch = search => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/clients/${search}`)
    .then(res =>
      dispatch({
        type: GET_SEARCH_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SEARCH_CLIENT,
        payload: null
      })
    );
};

// Get all clients
export const getClients = () => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get('/api/clients/all')
    .then(res =>
      dispatch({
        type: GET_CLIENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENTS,
        payload: null
      })
    );
};

// Get clients count
export const getClientsCount = () => dispatch =>{
  dispatch(setClientsLoading());
  axios
    .get('/api/clients/getClientsCount')
    .then(res=>
      dispatch({
        type: GET_CLIENTS_COUNT,
        payload: res.data
      }))
    .catch(err =>
      dispatch({
        type: GET_CLIENTS_COUNT,
        payload: null
      })
    );
};

// Get clients name and _id
export const getClientsNameAndId = nai_var => dispatch =>{
  dispatch(setClientsLoading());
  axios
    .get(`/api/clients/search/${nai_var}`)
    .then(res=>
      dispatch({
        type: GET_CLIENTS_NAI,
        payload: res.data
      }))
    .catch(err =>
      dispatch({
        type: GET_CLIENTS_NAI,
        payload: null
      })
    );
};

// Get all deleted clients
export const getDeletedClients = () => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get('/api/clients/all/deleted')
    .then(res =>
      dispatch({
        type: GET_DELETED_CLIENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DELETED_CLIENTS,
        payload: null
      })
    );
};

// Get client by id
export const getClientById = _id => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/clients/client/${_id}`)
    .then(res =>
      dispatch({
        type: GET_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENT,
        payload: null
      })
    );
};

// Delete Client
export const deleteClient = id => dispatch => {
  axios
    .delete(`/api/clients/${id}`)
    .then(res =>
      dispatch({ 
        type: DELETE_CLIENT,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Activate Ticket
export const activateClient = id => dispatch => {
  axios
    .post(`/api/clients/activate/${id}`)
    .then()
    .catch(clienterr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Profile loading
export const setClientsLoading = () => {
  return {
    type: CLIENTS_LOADING
  };
};
