import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getProfilesClient, deleteUser } from '../../actions/profileAction';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import { confirmAlert } from 'react-confirm-alert';

class ClientUserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
    };


  onDeleteClick(id) {
      confirmAlert({
          title: 'Izbriši korisnika',
          message: 'Da li sigurno želite da izbrišete korisnika?',
          buttons: [
              {
                  label: 'Da',
                  onClick: () => ((this.props.deleteUser(id), window.location.reload()))
              },
              {
                  label: 'Ne',
                  onClick: () => ''
              }
            ]  
      });
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }

  componentDidMount() {
    this.props.getProfilesClient();

  }

  render() {
    const { loading } = this.props.profile;

    const profiles = this.props.profile.profiles.filter(profiles => {
      return (
        profiles.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        profiles.email.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        profiles.client.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 
      );
    });

    let profileclientItems;

    if (profiles === null || loading) {
      profileclientItems = (
          <tr>
              <td>
                  <Spinner />
              </td>
          </tr>  
      );
    } else {
      if (profiles.length > 0) {
        profileclientItems = profiles.map(profile => (
          <tr key={profile._id}>
              <td>{profile.name}</td>
              <td>{profile.email}</td>
              <td>{profile.phone}</td>
              <td>{profile.client.name}</td>
              <td>{profile.position}</td>
              <td>
                  <div className="btn-group mb-4">
                      <Link
                        to={`/clientusers/${profile._id}`}
                        className="btn-fill btn btn-sm btn-info"
                      >
                        Izmeni
                      </Link>
                  </div>  
                  {/*<div className="btn-group mb-4">
                      <button
                        type="button"
                        className="btn-fill btn btn-sm btn-danger"
                        onClick={this.onDeleteClick.bind(this, profile._id)}
                      >
                        Izbriši
                      </button>
                  </div>*/}
              </td>
          </tr>      
        ));
      } else {
        profileclientItems = <h4>No profiles found...</h4>;
      }
    }

    return (
      <div className="content">
        <Container fluid>
          <input
            type="text"
            placeholder="Pretraga korisnika..."
            value={this.state.search}
            onChange={this.updateSearch.bind(this)}
            style={{ marginBottom: '5px' }}
            className="form-control form-control-lg col-md-4"
          />
          <Row>
              <Col md={12}>
                  <Card
                    title="Korisnici"
                    category="Informacije o korisnicima"
                    ctTableFullWidth
                    ctTableResponsive 
                    content={
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Ime</th>
                                    <th>Email</th>
                                    <th>Telefon</th>
                                    <th>Klijent</th>
                                    <th>Pozicija</th>
                                </tr>
                            </thead>
                            <tbody>{profileclientItems}</tbody>
                        </Table>
                    }
                  />
              </Col>
          </Row>        
        </Container>
      </div>
    );
  }
}

ClientUserTable.propTypes = {
  getProfilesClient: PropTypes.func.isRequired,
  deleteUser:PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  client: state.client
});

export default connect(
  mapStateToProps,
  { getProfilesClient, deleteUser }
)(ClientUserTable);
