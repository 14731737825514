import {
  ADD_CLIENT,
  GET_SEARCH_CLIENT,
  GET_CLIENTS,
  GET_CLIENTS_COUNT,
  GET_CLIENTS_NAI,
  GET_CLIENT,
  CLIENTS_LOADING,
  DELETE_CLIENT,
  GET_DELETED_CLIENTS
} from '../actions/types';

const initialState = {
  clients: [],
  clientsCount: [],
  clientsNai: [],
  deletedclients: [],
  clientSearch: [],
  client: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CLIENTS_COUNT:
      return{
        ...state,
        clientsCount: action.payload,
        loading: false
      };
    case GET_CLIENTS_NAI:
      return{
        ...state,
        clientsNai: action.payload,
        loading: false
      };
    case GET_SEARCH_CLIENT:
      return {
        ...state,
        clientSearch: action.payload,
        loading: false
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        loading: false
      };
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };
    case ADD_CLIENT:
      return {
        ...state,
        clients: [action.payload, ...state.clients]
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter(client => client._id !== action.payload)
      };
    case GET_DELETED_CLIENTS:
      return {
        ...state,
        deletedclients: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
