import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createClient } from '../../actions/clientActions';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pib: '',
      address: '',
      city: '',
      phone: '',
      email: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newClient = {
      name: this.state.name,
      email: this.state.email,
      pib: this.state.pib,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone
    };

    this.props.createClient(newClient, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title="Novi Klijent"
                category="Kreirajte klijenta"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Ime klijenta"
                          placeholder="Ime Klijenta"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChange}
                          error={errors.name}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Email adresa"
                          placeholder="Email Address"
                          name="email"
                          type="email"
                          value={this.state.email}
                          onChange={this.onChange}
                          error={errors.email}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="PIB klijenta"
                          placeholder="PIB klijenta"
                          name="pib"
                          value={this.state.pib}
                          onChange={this.onChange}
                          error={errors.pib}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Adresa klijenta"
                          placeholder="Adresa klijenta"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                          error={errors.address}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="grad klijenta"
                          placeholder="Grad klijenta"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                          error={errors.city}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="telefon klijenta"
                          placeholder="Telefon klijenta"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          error={errors.phone}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-2 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/clients"
                          className="btn-fill col-md-12 pull-center btn btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Klijenti
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddClient.propTypes = {
  createClient: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createClient }
)(withRouter(AddClient));
