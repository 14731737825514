import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDeletedDevices } from '../../actions/deviceActions';
import { getDeletedServices } from '../../actions/serviceActions';
import { getDeletedClients } from '../../actions/clientActions';
import Spinner from '../common/Spinner';
import DeletedDevices from './DeletedDevices.jsx';
import DeletedServices from './DeletedServices.jsx';
import DeletedClients from './DeletedClients.jsx';

class DeletedItems extends Component {
  componentDidMount() {
    if (this.props.auth.user.position === 'Admin') {
      this.props.getDeletedDevices();
      this.props.getDeletedServices();
      this.props.getDeletedClients();
    } else {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    let content = (
      <Container fluid>
        <Row>
          {this.props.device.loading ? (
            <Spinner />
          ) : (
            <DeletedDevices device={this.props.device} />
          )}
        </Row>
        <Row>
          {this.props.service.loading ? (
            <Spinner />
          ) : (
            <DeletedServices service={this.props.service} />
          )}
        </Row>
        <Row>
          {this.props.client.loading ? (
            <Spinner />
          ) : (
            <DeletedClients client={this.props.client} />
          )}
        </Row>
      </Container>
    );

    return <div className="content">{content}</div>;
  }
}

DeletedItems.propTypes = {
  getDeletedDevices: PropTypes.func.isRequired,
  getDeletedServices: PropTypes.func.isRequired,
  getDeletedClients: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  device: state.device,
  service: state.service,
  client: state.client
});

export default connect(
  mapStateToProps,
  { getDeletedDevices, getDeletedServices, getDeletedClients }
)(DeletedItems);
