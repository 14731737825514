import axios from 'axios';
import {
  GET_ERRORS,
  TICKETS_LOADING,
  GET_TICKETS,
  GET_TICKET,
  DELETE_TICKET,
  GET_TICKETS_BYUSER
} from './types';

// Create Ticket
export const createTicket = (ticketData, history) => dispatch => {
  axios
    .post('/api/tickets/create', ticketData)
    .then(res => history.push('/tickets'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// get Report Tickets
export const getReport = (createDate, endDate) => dispatch => {
  var fileDownload = require('js-file-download');
  dispatch(setTicketLoading())
  axios
  .get(`/api/tickets/getReport/${createDate}/${endDate}`, { responseType: 'blob'})
  .then(res =>{
    var currentDate=new Date();
    var date = new Intl.DateTimeFormat('en-US').format(currentDate);
    var filename = "Ticketing izvestaj " + date + ".xlsx";
      fileDownload(res.data, filename);
  })
  .catch(err => console.log('fuck'))
}
// get all tickets for specific user
export const getTicketsByUser = (userId) => dispatch => { 
  dispatch(setTicketLoading());
  axios
  .get(`/api/tickets/getTicketsByUser/${userId}`)
  .then(res => {
    dispatch({
      type: GET_TICKETS_BYUSER,
      payload: res.data
    })
  })
  .catch(err => {
    dispatch({
      type: GET_TICKETS_BYUSER,
      payload: null
    })
  })
}

// Get all tickets
export const getTickets = () => dispatch => {
  dispatch(setTicketLoading());
  axios
    .get('/api/tickets')
    .then(res =>
      dispatch({
        type: GET_TICKETS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TICKETS,
        payload: null
      })
    );
};

//Get ticketis ByDate
export const getTicketsByDate = (payload) => dispatch => {
  dispatch(setTicketLoading());
  axios
    .post('/api/tickets/getByDate', payload)
    .then(res =>
      dispatch({
        type: GET_TICKETS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TICKETS,
        payload: null
      })
    );
};

// Get all tickets by service center
export const getTicketsSC = service => dispatch => {
  dispatch(setTicketLoading());
  axios
    .get(`/api/tickets/service/${service}`)
    .then(res =>
      dispatch({
        type: GET_TICKETS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TICKETS,
        payload: null
      })
    );
};

// Get all tickets by user ID
export const getTicketsUID = userid => dispatch => {
  dispatch(setTicketLoading());
  axios
    .get(`/api/tickets/servicer/${userid}`)
    .then(res =>
      dispatch({
        type: GET_TICKETS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TICKETS,
        payload: null
      })
    );
};

// Get ticket by id
export const getTicketById = _id => dispatch => {
  dispatch(setTicketLoading());
  axios
    .get(`/api/tickets/${_id}`)
    .then(res =>
      dispatch({
        type: GET_TICKET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TICKET,
        payload: null
      })
    );
};

export const updateTicket = (ticket_id, ticketData, history) => dispatch => {
  axios
    .post(`/api/tickets/update/${ticket_id}`, ticketData)
    .then(res => history.push('/tickets'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
    //console.log(ticketData);
};
 
// Delete Ticket
export const deleteTicket = ( id, ticketData ) => dispatch => {
  axios
    .delete(`/api/tickets/${id}`, ticketData)
    .then(res =>
      dispatch({
        type: DELETE_TICKET,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Activate Ticket
export const activateTicket = id => dispatch => {
  axios
    .delete(`/api/tickets/activate/${id}`)
    .then()
    .catch(tickerr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Devices loading
export const setTicketLoading = () => {
  return {
    type: TICKETS_LOADING
  };
};
