import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class TicketHistory extends Component {
  render() {
    let styledelete;

    if (this.props.auth.user.position === 'Klijent') {
      styledelete = { width: '80px', visibility: 'hidden' };
    } else {
      styledelete = { width: '80px' };
    }
    const ticketlist = this.props.ticketlist.map(ticketlist => (
      <tr key={ticketlist._id}>
        <td>
          {(() => {
            switch (ticketlist.ticketid.status) {
              case 5:
                return (
                  <span className="btn-fill btn btn-sm btn-danger">
                    Otkazan
                  </span>
                );
              case 1:
                return (
                  <span className="btn-fill btn btn-sm btn-info">Novi</span>
                );
              case 2:
                return (
                  <span className="btn-fill btn btn-sm btn-default">
                    Na cekanju
                  </span>
                );
              case 3:
                return (
                  <span className="btn-fill btn btn-sm btn-warning">
                    U toku
                  </span>
                );
              case 4:
                return (
                  <span className="btn-fill btn btn-sm btn-success">
                    Zavrsen
                  </span>
                );
              default:
                return null;
            }
          })()}
        </td>
        <td>
          {(() => {
            switch (ticketlist.ticketid.priority) {
              case 1:
                return (
                  <span className="btn-fill btn btn-sm btn-success">Low</span>
                );
              case 2:
                return (
                  <span className="btn-fill btn btn-sm btn-info">Medium</span>
                );
              case 3:
                return (
                  <span className="btn-fill btn btn-sm btn-warning">High</span>
                );
              case 4:
                return (
                  <span className="btn-fill btn btn-sm btn-danger">Urgent</span>
                );
              default:
                return null;
            }
          })()}
        </td>
        <td>{ticketlist.ticketid.desc}</td>
        <td>
          <Moment format="DD.MM.YYYY HH:mm">
            {ticketlist.ticketid.datecreate}
          </Moment>
        </td>
        <td>
          <Link
            to={`/tickets/${ticketlist.ticketid._id}`}
            className="btn-fill btn btn-sm btn-info"
            style={styledelete}
          >
            Info
          </Link>
        </td>
      </tr>
    ));
    return (
      <Table striped hover>
        <thead>
          <tr>
            <th>Status</th>
            <th>Prioritet</th>
            <th>Opis</th>
            <th>Datum Kreiranja</th>
            <th />
          </tr>
        </thead>
        <tbody>{ticketlist}</tbody>
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(TicketHistory);
