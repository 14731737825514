import { Container, Row, Col, Table } from 'react-bootstrap';
import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import Moment from 'react-moment';
import isEmpty from '../../validation/is-empty';
import Card from '../../components/Card/Card.jsx';
import Pagination from '../common/Pagination';
import { connect } from 'react-redux';
import { activateDevice } from '../../actions/deviceActions';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';

class DeletedDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alldevices: props.device.deleteddevices,
      currentDevices: [],
      currentPage: null,
      totalPages: null
    };
  }

  onPageChanged = data => {
    const { alldevices } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentDevices = alldevices.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentDevices, totalPages });
  };

  onActivateClick(id) {
    confirmAlert({
      title: 'Aktiviraj uredjaj',
      message: 'Da li sigurno zelite da aktivirate?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.activateDevice(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.deleteTicket(id);
  }

  render() {
    const { loading } = this.props.device;
    const { alldevices, currentDevices } = this.state;
    const nextweek = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 7
    );
    const nextmonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 30
    );
    let devicesItems;
    if (alldevices === null || loading) {
      devicesItems = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
        </tr>
      );
    } else {
      if (alldevices.length > 0) {
        devicesItems = currentDevices.map(device => (
          <tr key={device._id}>
            <td>{device.client.name}</td>
            <td>{device.branch}</td>
            <td>{device.address}</td>
            <td>{device.city}</td>
            <td>{device.ibfk}</td>
            <td>
              <Moment format="DD.MM.YYYY">{device.dateoffiscal}</Moment>
            </td>
            <td>
              {isEmpty(device.annualservices) ? null : (
                <Moment format="DD.MM.YYYY">
                  {device.annualservices[0].date}
                </Moment>
              )}
            </td>
            <td>
              {new Date(device.nextannual) < nextweek ? (
                <p>
                  <span>
                    <Moment format="DD.MM.YYYY">{device.nextannual}</Moment>
                  </span>

                  <span
                    className="btn-fill btn-sm btn-danger"
                    style={{ marginLeft: '3px' }}
                  >
                    High
                  </span>
                </p>
              ) : new Date(device.nextannual) < nextmonth ? (
                <p>
                  <span>
                    <Moment format="DD.MM.YYYY">{device.nextannual}</Moment>
                  </span>

                  <span
                    className="btn-fill btn-warning btn-sm"
                    style={{ marginLeft: '3px' }}
                  >
                    Medium
                  </span>
                </p>
              ) : (
                <p>
                  <span>
                    <Moment format="DD.MM.YYYY">{device.nextannual}</Moment>
                  </span>

                  <span
                    className="btn-fill btn-success btn-sm"
                    style={{ marginLeft: '3px' }}
                  >
                    Low
                  </span>
                </p>
              )}
            </td>
            <td>
              <button
                onClick={this.onActivateClick.bind(this, device._id)}
                className="btn-fill btn btn-sm btn-info"
              >
                Aktiviraj
              </button>
            </td>
          </tr>
        ));
      } else {
        devicesItems = (
          <tr>
            <td>No devices found...</td>
          </tr>
        );
      }
    }

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="pull-right" style={{ marginRight: '5px' }}>
                <Pagination
                  totalRecords={alldevices.length}
                  pageLimit={10}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
              <Card
                title="Lista izbrisanih uredjaja"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>Klijent</th>
                          <th>Ogranak</th>
                          <th>Adresa</th>
                          <th>Grad</th>
                          <th>IBFK</th>
                          <th>Fiskalizacija</th>
                          <th>Poslednji tehnicki</th>
                          <th>Naredni tehnicki</th>
                          <th>Aktiviraj</th>
                        </tr>
                      </thead>
                      <tbody>{devicesItems /*.slice(0, 10)*/}</tbody>
                    </Table>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

DeletedDevices.propTypes = {
  activateDevice: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { activateDevice }
)(DeletedDevices);
