import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getTicketsByUser } from '../../actions/ticketActions';
import { getClientById, createClient } from '../../actions/clientActions';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import { Card } from '../../components/Card/Card';
import Moment from 'react-moment';

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pib: '',
      address: '',
      city: '',
      phone: '',
      email: '',
      vendor: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newClient = {
      name: this.state.name,
      email: this.state.email,
      pib: this.state.pib,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone
      //vendor: this.state.vendor
    };

    this.props.createClient(newClient, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.client.client) {
      const client = nextProps.client.client;

      client.phone = !isEmpty(client.phone) ? client.phone : '';
      client.pib = !isEmpty(client.pib) ? client.pib : '';
      client.name = !isEmpty(client.name) ? client.name : '';
      client.address = !isEmpty(client.address) ? client.address : '';
      client.city = !isEmpty(client.city) ? client.city : '';
      client.email = !isEmpty(client.email) ? client.email : '';
      //client.vendor = !isEmpty(client.vendor) ? client.vendor : '';


      // Set component fields state
      this.setState({
        phone: client.phone,
        pib: client.pib,
        address: client.address,
        city: client.city,
        email: client.email,
        name: client.name,
        //vendor: client.vendor
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    if (this.props.match.params._id) {
      this.props.getTicketsByUser(this.props.match.params._id);
      this.props.getClientById(this.props.match.params._id);
    }
  }

  render() {
    const { errors } = this.state;

    const { client, loading } = this.props.client;

    const tickets = this.props.ticket.ticketsByUser;

    let ticketItems2;
    if (tickets == null || loading) {
      ticketItems2 = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      ticketItems2 = tickets.map(ticket => {
        return (
          <tr key={ticket._id}>
            <td>
              {isEmpty(ticket.device) ? 'Nije u bazi' : ticket.device.ibfk}
            </td>
            <td>
              {(() => {
                switch (ticket.priority) {
                  case 1:
                    return (
                      <span className="btn-fill btn btn-sm btn-success">
                        Low
                      </span>
                    );
                  case 2:
                    return (
                      <span className="btn-fill btn btn-sm btn-info">
                        Medium
                      </span>
                    );
                  case 3:
                    return (
                      <span className="btn-fill btn btn-sm btn-warning">
                        High
                      </span>
                    );
                  case 4:
                    return (
                      <span className="btn-fill btn btn-sm btn-danger">
                        Urgent
                      </span>
                    );
                  default:
                    return null;
                }
              })()}
            </td>
            <td>
              {isEmpty(ticket.device) ? '' : ticket.device.address},{' '}
              {isEmpty(ticket.device) ? '' : ticket.device.city}
            </td>
            <td>
              {isEmpty(ticket.device) ? '' : ticket.service.name}
            </td>
            <td>
              {isEmpty(ticket.servicer) ? '' : ticket.servicer.name}
            </td>
            <td>{ticket.desc}</td>
            <td>
              {(() => {
                switch (ticket.status) {
                  case 5:
                    return (
                      (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Otkazan
                        </span>
                      )
                    );
                  case 1:
                    return (
                      <span className="btn-fill btn btn-sm btn-info">
                        Novi
                      </span>
                    );
                  case 2:
                    return (
                      <span className="btn-fill btn btn-sm btn-default">
                        Na cekanju
                      </span>
                    );
                  case 3:
                    return (
                      <span className="btn-fill btn btn-sm btn-warning">
                        U toku
                      </span>
                    );
                  case 4:
                    return (
                      (
                        <span className="btn-fill btn btn-sm btn-success">
                          Zavrsen
                        </span>
                      )
                    );
                  default:
                    return null;
                }
              })()}
            </td>
            <td>
              <Moment format="DD.MM.YYYY">{ticket.datecreate}</Moment>
            </td>
            <td>
              {isEmpty(ticket.dateend) ? (
                ''
              ) : (
                <Moment format="DD.MM.YYYY">{ticket.dateend}</Moment>
              )}
            </td>
            <td>{isEmpty(ticket.exectime) ? '' : ticket.exectime}</td>
            <td>{isEmpty(ticket.parts) ? '' : ticket.parts}</td>
            <td>{isEmpty(ticket.user) ? '' : ticket.user.name}</td>
          </tr>
        );
      });
    }


    let clientContent;
    if (client === null || loading) {
      clientContent = <Spinner />;
    } else {
      clientContent = (
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title={this.state.name}
                category="Podaci klijenta"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Ime klijenta"
                          placeholder="Ime Klijenta"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChange}
                          error={errors.name}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Email adresa"
                          placeholder="Email Address"
                          name="email"
                          type="email"
                          value={this.state.email}
                          onChange={this.onChange}
                          error={errors.email}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="PIB klijenta"
                          placeholder="PIB klijenta"
                          name="pib"
                          value={this.state.pib}
                          error={errors.pib}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Adresa klijenta"
                          placeholder="Adresa klijenta"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                          error={errors.address}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="grad klijenta"
                          placeholder="Grad klijenta"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                          error={errors.city}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="telefon klijenta"
                          placeholder="Telefon klijenta"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          error={errors.phone}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Link
                          to="/clients"
                          className="btn-fill col-md-12 pull-center btn btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Klijenti
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Card
              title="Informacije o zavrsenim i otkazanim tiketima"
              category="Lista tiketa"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover id="table-to-xls">
                  <thead>
                    <tr>
                      <th>Uredjaj</th>
                      <th>Prioritet</th>
                      <th>Adresa</th>
                      <th>Servis</th>
                      <th>Serviser</th>
                      <th>Opis kvara</th>
                      <th>Status</th>
                      <th>Datum kreiranja</th>
                      <th>Datum zatvaranja</th>
                      <th>Utroseno vreme (Days_HH:MM)</th>
                      <th>Opis popravke</th>
                      <th>Korisnik</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>{ticketItems2}</tbody>
                </Table>
              }
            />
          </Row>
        </Container>
      );
    }
    return <div className="content">{clientContent}</div>;
  }
}

Client.propTypes = {
  getTicketsByUser: PropTypes.func.isRequired,
  getClientById: PropTypes.func.isRequired,
  createClient: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  ticket: state.ticket,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getClientById, createClient, getTicketsByUser }
)(Client);
