import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createService } from '../../actions/serviceActions';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';

class AddService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      city: '',
      phone: '',
      email: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newService = {
      name: this.state.name,
      email: this.state.email,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone
    };

    this.props.createService(newService, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
      // <div className="register">
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-md-8 m-auto">
      //         <h1 className="display-4 text-center">Novi Servis</h1>
      //         <p className="lead text-center">Kreiranje Servisa</p>
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Novi servis"
                category="Podaci servisa"
                content={
                  <form onSubmit={this.onSubmit}>
                    <TextFieldGroup
                      label="Ime Servisa"
                      placeholder="Ime Servisa"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      error={errors.name}
                    />
                    <TextFieldGroup
                      label="Email Adresa"
                      placeholder="Email Adresa"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      error={errors.email}
                    />
                    <TextFieldGroup
                      label="Adresa servisa"
                      placeholder="Adresa servisa"
                      name="address"
                      value={this.state.address}
                      onChange={this.onChange}
                      error={errors.address}
                    />
                    <TextFieldGroup
                      label="Grad"
                      placeholder="Grad"
                      name="city"
                      value={this.state.city}
                      onChange={this.onChange}
                      error={errors.city}
                    />
                    <TextFieldGroup
                      label="Telefon"
                      placeholder="Telefon servisa"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.onChange}
                      error={errors.phone}
                    />

                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/services"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Servisi
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddService.propTypes = {
  createService: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  service: state.service,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createService }
)(withRouter(AddService));
