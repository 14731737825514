import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerClient } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import SelectListGroup from '../common/SelectListGroup';
import { getClients } from '../../actions/clientActions';

class RegisterClient extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      client: '',
      phone: '',
      branch: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      client: this.state.client,
      phone: this.state.phone,
      branch: this.state.branch
    };

    this.props.registerClient(newUser, this.props.history);
  }

  componentDidMount() {
    this.props.getClients();
    if (this.props.auth.user.position !== 'Admin') {
      this.props.history.push('/dashboard');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    const { clients } = this.props.clients;
    
    const options = clients.map(client => ({
      label: client.name,
      value: client._id
    }));
    options.unshift({ label: '* Selektujte klijenta', value: '' });
    return (
      <div>
        <form noValidate onSubmit={this.onSubmit}>
          <TextFieldGroup
            label="Ime i prezime"
            placeholder="Ime i Prezime"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
            error={errors.nameclient}
          />
          <TextFieldGroup
            label="Email Adresa"
            placeholder="Email Adresa"
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.onChange}
            error={errors.emailclient}
          />
          <TextFieldGroup
            label="Lozinka"
            placeholder="Lozinka"
            name="password"
            type="password"
            value={this.state.password} 
            onChange={this.onChange}
            error={errors.passwordclient}
          />
          <TextFieldGroup
            label="Potvrdi lozinku"
            placeholder="Potvrdi lozinku"
            name="password2"
            type="password"
            value={this.state.password2}
            onChange={this.onChange}
            error={errors.password2client}
          />
          <SelectListGroup
            placeholder="Klijent"
            name="client"
            value={this.state.client}
            onChange={this.onChange}
            options={options}
            error={errors.clientclient}
            labels="Klijent"
          />
          <TextFieldGroup
            label="Telefon"
            placeholder="Telefon"
            name="phone"
            value={this.state.phone}
            onChange={this.onChange}
            error={errors.phoneclient}
          />
          <TextFieldGroup
            label="Adresa"
            placeholder="Adresa"
            name="branch"
            value={this.state.branch}
            onChange={this.onChange}
            error={errors.branchclient}
          />
          <input
            type="submit"
            value="Registruj korisnika"
            className="btn btn-fill btn-info btn-block mt-4"
          />
        </form>
      </div>
    );
  }
}

RegisterClient.propTypes = {
  registerClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  clients: state.client
});

export default connect(
  mapStateToProps,
  { registerClient, getClients }
)(withRouter(RegisterClient));
