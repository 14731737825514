import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import store from '../../store';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileAction';
import PrivateRoute from '../../components/common/PrivateRoute';
import Client from '../../components/client/Client';
import Clients from '../../components/clients/Clients';
import Register from '../../components/auth/Register';
import RegisterClientSelf from '../../components/auth/RegisterClientSelf';
import Login from '../../components/auth/Login';
import CreateProfile from '../../components/create-profile/CreateProfile';
import EditProfile from '../../components/edit-profile/EditProfile';
import AddClient from '../../components/add-elements/AddClient';
import AddService from '../../components/add-elements/AddService';
import AddDevice from '../../components/add-elements/AddDevice';
import AddTicket from '../../components/add-elements/AddTicket';
import Devices from '../../components/devices/Devices';
import Device from '../../components/device/Device';
import DeviceClient from '../../components/device/DeviceClient';
import AnnualService from '../../components/device/AnnualService';
import Dashboard2 from '../../components/dashboard/Dashboard';
import DashboardClient from '../../components/dashboard/DashboardClient';
import Services from '../../components/services/Services';
import Service from '../../components/services/Service';
import Profiles from '../../components/profiles/Profiles';
import ProfileEdit from '../../components/profiles/ProfileEdit';
import Tickets from '../../components/tickets/Tickets';
import TicketsClient from '../../components/tickets/TicketsClient';
import Ticket from '../../components/tickets/Ticket';
import DeletedItems from '../../components/deleted/DeletedItems';
import ClientUsersTable from '../../components/clientUsers/clientUsersTable';
import clientUserItem from '../../components/clientUsers/clientUserItem';
import AddClientFromTechTool from '../../components/add-elements/AddClientFromTechTool';
import PrivPol from '../../components/PP/PrivPol'

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';

import { style } from '../../variables/Variables.jsx';
import TicketsForReportTable from '../../components/tickets/TicketsForReportTable'
import dashboardRoutes from '../../routes/dashboard.jsx';
import adminRoutes from '../../routes/admin.jsx';
import clientuserRoutes from '../../routes/clientuser.jsx';
import serviceRoutes from '../../routes/serviser.jsx';
import pmRoutes from '../../routes/pm.jsx';
import EditProfileClient from '../../components/edit-profile/EditProfileClient';
import AddTicketClient from '../../components/add-elements/AddTicketClient';
import AddRequest from '../../components/add-elements/AddRequest';
import RequestsTable from '../../components/requests/RequestsTable';
import Request from '../../components/requests/Request';
import RequestsTableClient from '../../components/requests/RequestsTableClient';
import AddRequestClient from '../../components/add-elements/AddRequestClient';

//Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/login';
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null
    };
  }
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    var _notificationSystem = this.refs.notificationSystem;
    console.log(_notificationSystem)
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    console.log(level);
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    let route;
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'Admin') {
        route = adminRoutes;
      } else if (this.props.auth.user.position === 'Klijent') {
        route = clientuserRoutes;
      } else if (this.props.auth.user.position === 'Serviser') {
        route = serviceRoutes;
      } else if (this.props.auth.user.position === 'PM') {
        route = pmRoutes;
      } else {
        route = dashboardRoutes;
      }
    } else {
      route = [
        { path: '/PrivacyPolicy', to: '/PrivacyPolicy', name: '/PrivacyPolicy' },
        { path: '/', to: '/login', name: 'Login' }
      ];
    }
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registerClient" component={RegisterClientSelf} />
          <Route exact path="/PrivacyPolicy" component={PrivPol} />
          <Switch>
            {route.map((prop, key) => {
              if (prop.name === 'Notifications')
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    render={routeProps => (
                      <prop.component
                        {...routeProps}
                        handleClick={this.handleNotificationClick}
                      />
                    )}
                  />
                );
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard2} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboardclient"
              component={DashboardClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboardclient/:_id"
              component={DeviceClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/edit-profile" component={EditProfile} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/profile-client"
              component={EditProfileClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/edit-profile/create-profile"
              component={CreateProfile}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/clients/add/add-client"
              component={AddClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/services/add/add-service"
              component={AddService}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/devices/add/add-device"
              component={AddDevice}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/tickets/add/add-ticket"
              component={AddTicket}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/requests/add/add-request"
              component={AddRequest}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/tickets/add/add-ticket-from-tt" component={AddClientFromTechTool} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/requests" component={RequestsTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients" component={Clients} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients/:_id" component={Client} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/devices" component={Devices} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/devices/:_id" component={Device} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/devices/annual/:_id" component={AnnualService}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/services" component={Services} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/services/:_id" component={Service} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/profiles" component={Profiles} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/profiles/:id" component={ProfileEdit} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientusers" component={ClientUsersTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientusers/:id" component={clientUserItem} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/register" component={Register} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/deleted" component={DeletedItems} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/tickets" component={Tickets} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/TicketsForReport" component={TicketsForReportTable} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/ticketsclient"
              component={TicketsClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/ticketsclient/add/add-ticket"
              component={AddTicketClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/tickets/:_id" component={Ticket} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/requests/:_id" component={Request} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/requestsclient"
              component={RequestsTableClient}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/requestsclient/add/add-request"
              component={AddRequestClient}
            />
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Dashboard);
