import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';
import { Link } from 'react-router-dom';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'Klijent') {
        this.props.history.push('/dashboardclient');
      } else {
        this.props.history.push('/dashboard');
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'Klijent') {
        this.props.history.push('/dashboardclient');
      } else {
        this.props.history.push('/dashboard');
      }
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="content">
        <Container fluid>
          <Row style={{ paddingTop: '5%' }}>
            <Col md={5} className="pull-center">
              <Card
                content={
                  <div>
                    <h1 className="display-4 text-center">Login</h1>
                    <p className="lead text-center">
                      Ulogujte sa na svoj GalebTicketing nalog
                    </p>
                    <form noValidate onSubmit={this.onSubmit}>
                      <TextFieldGroup
                        placeholder="Email Adresa"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors.email}
                      />

                      <TextFieldGroup
                        placeholder="Password"
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                      />

                      <input
                        type="submit"
                        style={{ width: '120px' }}
                        value="Login"
                        className="btn btn-info btn-fill btn-block mt-4"
                      />
                      
                      <Link
                        to={`/registerClient`}
                        style={{ float: 'right', width: '120px'}}
                        className= "btn btn-info btn-fill btn-block mt-4"
                      >
                        Registruj se
                      </Link>
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
