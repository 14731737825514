import React, { Component } from 'react';
import TicketTable from './TicketTable';

class Tickets extends Component {
  render() {
    return (
      <div className="content">
        <TicketTable />
      </div>
    );
  }
}

export default Tickets;
