import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { getDeviceById } from '../../actions/deviceActions';
import isEmpty from '../../validation/is-empty';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card.jsx';
import TicketHistory from './TicketHistory';

class DeviceClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      service: '',
      address: '',
      city: '',
      phone: '',
      mobile: '',
      description: '',
      ibfk: '',
      ticketlist: [],
      errors: {}
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.device.device) {
      const device = nextProps.device.device;

      device._id = !isEmpty(device._id) ? device._id : '';
      device.client = !isEmpty(device.client) ? device.client : '';
      device.service = !isEmpty(device.service) ? device.service : '';
      //device.branch = !isEmpty(device.branch) ? device.branch : '';
      device.address = !isEmpty(device.address) ? device.address : '';
      device.city = !isEmpty(device.city) ? device.city : '';
      device.phone = !isEmpty(device.phone) ? device.phone : '';
      device.mobile = !isEmpty(device.mobile) ? device.mobile : '';
      device.description = !isEmpty(device.description)
        ? device.description
        : '';
      //device.devicemodel = !isEmpty(device.devicemodel)
      //  ? device.devicemodel
      //  : '';
      device.ibfk = !isEmpty(device.ibfk) ? device.ibfk : '';
      //device.ibfm = !isEmpty(device.ibfm) ? device.ibfm : '';
      //device.dateoffiscal = !isEmpty(device.dateoffiscal)
      //  ? device.dateoffiscal
      //  : '';
      //device.nextannual = !isEmpty(device.nextannual) ? device.nextannual : '';

      // Set component fields state
      this.setState({
        _id: device._id,
        client: device.client,
        service: device.service,
        //branch: device.branch,
        address: device.address,
        city: device.city,
        phone: device.phone,
        mobile: device.mobile,
        description: device.description,
        //devicemodel: device.devicemodel,
        ibfk: device.ibfk,
        //ibfm: device.ibfm,
        //dateoffiscal: device.dateoffiscal,
        //nextannual: device.nextannual,
        //annualservices: device.annualservices,
        ticketlist: device.ticketlist
      });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params._id) {
      this.props.getDeviceById(this.props.match.params._id);
    }
  }

  render() {
    const { device, loading } = this.props.device;

    let deviceContent;
    if (device === null || loading) {
      deviceContent = <Spinner />;
    } else {
      deviceContent = (
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.state.ibfk}
                category="Podaci uredjaja"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      {/*<div className="col-md-4">
                        <label className="control-label">Ogranak</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.branch}
                        </h6>
                      </div>*/}
                      <div className="col-md-8">
                        <label className="control-label">Adresa</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.address}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="control-label">Grad</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.city}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <label className="control-label">Telefon</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.phone}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <label className="control-label">Mobilni</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.mobile}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      {/*<div className="col-md-4">
                        <label className="control-label">Model uredjaja</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.devicemodel}
                        </h6>
                      </div>*/}
                      <div className="col-md-4">
                        <label className="control-label">
                          Serijski broj uredjaja
                        </label>
                        <h6 className="form-control form-control-lg">
                          {this.state.ibfk}
                        </h6>
                      </div>
                      {/*<div className="col-md-4">
                        <label className="control-label">
                          Identifikacioni broj modula
                        </label>
                        <h6 className="form-control form-control-lg">
                          {this.state.ibfm}
                        </h6>
                      </div>*/}
                    </div>
                    {/*<div className="row">
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Datum fiskalzacije:{' '}
                          {isEmpty(this.state.dateoffiscal) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY">
                                {this.state.dateoffiscal}
                              </Moment>
                            </span>
                          )}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Datum tehnickog:{' '}
                          {isEmpty(this.state.nextannual) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY">
                                {this.state.nextannual}
                              </Moment>
                            </span>
                          )}
                        </h6>
                      </div>
                    </div>*/}
                    <div className="row">
                      <div className="col-md-12">
                        <label className="control-label">Opis/Komentar</label>
                        <h6 className="form-control form-control-lg">
                          {this.state.description}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 pull-right">
                        <Link
                          to="/dashboardclient"
                          className="btn-fill col-md-12 btn pull-right btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Uredjaje
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                title="Tiketi"
                category="Lista tiketa"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <TicketHistory ticketlist={this.state.ticketlist} />
                  </div>
                }
              />
            </Col>
            {/*<Col md={4}>
              <Card
                title="Tehnicki pregledi"
                category="Lista ranije izvrsenih tehnickih pregleda"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <AnnualHistory annualservices={this.state.annualservices} />
                  </div>
                }
              />
            </Col>*/}
          </Row>
        </Container>
      );
    }

    return <div className="content"> {deviceContent}</div>;
  }
}

DeviceClient.propTypes = {
  getDeviceById: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  device: state.device
});

export default connect(
  mapStateToProps,
  { getDeviceById }
)(withRouter(DeviceClient));
