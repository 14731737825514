import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getServices, deleteService } from '../../actions/serviceActions';
import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { confirmAlert } from 'react-confirm-alert';

class ServiceTable extends Component {
  constructor() {
    super();
    this.state = {
      search: ''
    };
  }

  onDeleteClick(id) {
    confirmAlert({
      title: 'Izbrisi Servis',
      message: 'Da li sigurno zelite da izbrisete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.deleteService(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.deleteService(id);
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }

  componentDidMount() {
    this.props.getServices();
  }

  render() {
    const { loading } = this.props.service;

    const services = this.props.service.services.filter(services => {
      return (
        services.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
        services.address.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
        services.city.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      );
    });

    let serviceItems;
    if (services === null || loading) {
      serviceItems = (
        <tr>
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      if (services.length > 0) {
        serviceItems = services.map(service => (
          <tr key={service._id}>
            <td>{service.name}</td>
            <td>{service.address}</td>
            <td>{service.city}</td>
            <td>{service.phone}</td>
            <td>{service.email}</td>
            <td>
              <Link
                to={`/services/${service._id}`}
                className="btn-fill btn btn-info btn-sm"
                style={{ width: '100px' }}
              >
                Info
              </Link>
            </td>
            <td>
              <button
                style={{ width: '100px' }}
                onClick={this.onDeleteClick.bind(this, service._id)}
                className="btn-fill btn btn-danger btn-sm"
              >
                Delete
              </button>
            </td>
          </tr>
        ));
      } else {
        serviceItems = (
          <tr>
            <td>No services found...</td>
          </tr>
        );
      }
    }
    return (
      <div className="content">
        <Container fluid>
          <Link
            to="/services/add/add-service"
            className="btn btn-fill btn-info btn-sm"
            style={{ marginBottom: '5px' }}
          >
            <i className="pe-7s-plus" /> Novi Servis{' '}
          </Link>
          <input
            type="text"
            placeholder="Pretraga servisa..."
            value={this.state.search}
            onChange={this.updateSearch.bind(this)}
            style={{ marginBottom: '5px' }}
            className="form-control form-control-lg col-md-4"
          />
          <Row>
            <Col md={12}>
              <Card
                title="Servisi"
                category="Informacije o servisima"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Ime</th>
                        <th>Adresa</th>
                        <th>Grad</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>{serviceItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ServiceTable.propTypes = {
  deleteService: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  service: state.service
});

export default connect(
  mapStateToProps,
  { getServices, deleteService }
)(ServiceTable);
