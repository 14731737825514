import {
  GET_REQUESTS,
  REQUESTS_LOADING,
  GET_REQUEST,
  DELETE_REQUEST
} from '../actions/types';

const initialState = {
  requests: [],
  request: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUESTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_REQUESTS:
      return {
        ...state,
        requests: action.payload,
        loading: false
      };
    case GET_REQUEST:
      return {
        ...state,
        request: action.payload,
        loading: false
      };
    case DELETE_REQUEST:
      return {
        ...state,
        requests: state.requests.filter(
          request => request._id !== action.payload
        )
      };
    default:
      return state;
  }
}
