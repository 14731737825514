import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import SelectListGroup from '../common/SelectListGroup'
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { getTicketById, updateTicket } from '../../actions/ticketActions';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import Moment from 'react-moment';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card.jsx';
import classnames from 'classnames';
import {getProfilesAdmin} from '../../actions/profileAction';
import {getServices} from '../../actions/serviceActions';

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: this.props.client,
      device: '',
      address: '',
      priority: '',
      status: '',
      servicer: '',
      service: '',
      desc: '',
      parts: '',
      datecreate: '',
      dateend: '',
      phone: '',
      mobile: '',
      user: '',
      serviserpre: '',
      statuspre: '',
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getServices();
    this.props.getProfilesAdmin();
  }

  onSubmit(e) {
    e.preventDefault();
    const newTick = {
      client: this.state.client,
      priority: this.state.priority,
      status: this.state.status,
      service: this.state.service,
      servicer: this.state.servicer,
      user: this.props.ticket.ticket.user,
      desc: this.state.desc,
      device: this.props.ticket.ticket.device,
      parts: this.state.parts,
      serviserpre: this.state.serviserpre,
      statuspre: this.state.statuspre,
      datecreate: this.state.datecreate
    };
    this.props.updateTicket(this.state._id, newTick, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.ticket.ticket) {
      const ticket = nextProps.ticket.ticket;
      ticket._id = !isEmpty(ticket._id) ? ticket._id : '';
      ticket.device = !isEmpty(ticket.device) ? ticket.device : '';
      ticket.client = !isEmpty(ticket.client) ? ticket.client : '';
      ticket.priority = !isEmpty(ticket.priority) ? ticket.priority : '';
      ticket.status = !isEmpty(ticket.status) ? ticket.status : '';
      ticket.service= !isEmpty(ticket.service) ? ticket.service : '';
      ticket.servicer= !isEmpty(ticket.servicer) ? ticket.servicer : '';
      ticket.desc = !isEmpty(ticket.desc) ? ticket.desc : '';
      ticket.parts = !isEmpty(ticket.parts) ? ticket.parts : '';
      ticket.datecreate = !isEmpty(ticket.datecreate) ? ticket.datecreate : '';
      ticket.dateend = !isEmpty(ticket.dateend) ? ticket.dateend : '';
      ticket.servicer = !isEmpty(ticket.servicer) ? ticket.servicer : '';
      ticket.status = !isEmpty(ticket.status) ? ticket.status : '';

      // Set component fields state
      this.setState({
        _id: ticket._id,
        client: !isEmpty(ticket.client) ? ticket.client.name: '',
        device: !isEmpty(ticket.device) ? ticket.device.ibfk : '',
        phone: !isEmpty(ticket.device) ? ticket.device.phone : '',
        mobile: !isEmpty(ticket.device) ? ticket.device.mobile : '',
        address: ticket.device.address + ', ' + ticket.device.city,
        priority: ticket.priority,
        status: ticket.status,
        service: ticket.service,
        servicer: ticket.servicer,
        desc: ticket.desc,
        parts: ticket.parts,
        datecreate: ticket.datecreate,
        dateend: ticket.dateend,
        serviserpre: ticket.servicer,
        statuspre: ticket.status,
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  UNSAFE_componentWillMount() {
    if (this.props.match.params._id) {
      this.props.getTicketById(this.props.match.params._id);
    }
  }

  render() {
    const { ticket, loading } = this.props.ticket;
    const { errors } = this.state;
    //const { profiles } = this.props.profiles;
    const { services } = this.props.services;
    // eslint-disable-next-line
    this.state.serviserpre = this.props.ticket.ticket.servicer;
    // eslint-disable-next-line
    this.state.statuspre = this.props.ticket.ticket.status;

    const optionsService = services.map(service => ({
      label: service.name,
      value: service._id
    }));
    optionsService.unshift({ label: '* Selektujte servis', value: '' });

    const profiles = this.props.profiles.profiles.filter(profiles => {
      return !isEmpty(this.state.service._id)
        ? profiles.service._id
          .toLowerCase()
          .indexOf(this.state.service._id.toLowerCase()) !== -1
        : profiles;
    });

    const optionsProfile = profiles.map( profile => ({
      label: profile.name,
      value: profile._id
    }))

    optionsProfile.unshift({ label: '* Selektujte servisera', value: '' });
    
    let ticketContent;
    if (ticket === null || loading) {
      ticketContent = <Spinner />;
    } else {
      ticketContent = (
        <Container fluid>
          <Row>
            <Col md={10}>
              <Card
                title="Informacije o tiketu"
                category=""
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                    <TextFieldGroup
                          label="client"
                          placeholder="client"
                          name="client"
                          value={this.state.client}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Uredjaj"
                          placeholder="Uredjaj"
                          name="ticket"
                          value={this.state.device}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Adresa"
                          placeholder="Adresa"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-2">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            'http://maps.google.com/?q=' + this.state.address
                          }
                        >
                          <br />
                          <span
                            className="btn-fill col-md-12 btn pull-center btn-info"
                            style={{ margin: '3px' }}
                          >
                            <i className="pe-7s-map-marker" />
                            {'  Pronadji na mapi '}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <a href={'tel:' + this.state.phone}>
                          <label>Telefon </label>
                          <span
                            className="btn-fill col-md-12 btn pull-center btn-success"
                            style={{ margin: '3px' }}
                          >
                            <i className="pe-7s-call" />
                            {' ' + this.state.phone}
                          </span>
                        </a>
                      </div>
                      <div className="col-md-6">
                        <a href={'tel:' + this.state.mobile}>
                          <label>Mobilni </label>
                          <span
                            className="btn-fill col-md-12 btn pull-center btn-success"
                            style={{ margin: '3px' }}
                          >
                            <i className="pe-7s-phone" />
                            {' ' + this.state.mobile}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">PRIORITET</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.priority
                          })}
                          name="priority"
                          value={this.state.priority}
                          onChange={this.onChange}
                          disabled={this.props.profiles.profile.position !== 'Admin'}
                        >
                          <option value="">* Selektuj Prioritet</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                          <option value="4">Urgent</option>
                        </select>
                        <div className="text-danger">
                          {errors.priorityticket}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="control-label">Status</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.status
                          })}
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <option value="">* Selektuj Status</option>
                          <option value="1">Novi</option>
                          <option value="2">Na cekanju</option>
                          <option value="3">U toku</option>
                          <option value="4">Zavrsen</option>
                          <option value="5">Otkazan</option>
                        </select>
                        <div className="text-danger">{errors.statusticket}</div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <SelectListGroup
                          labels="Servis"
                          placeholder="Service"
                          name="service"
                          value={this.state.service._id}
                          onChange={this.onChange}
                          options={optionsService}
                          error={errors.service}
                          disabled={this.props.profiles.profile.position !== 'Admin'}
                        />
                      </div>
                        <div className="col-md-6">
                        <SelectListGroup
                          labels="Serviser"
                          placeholder="Servicer"
                          name="servicer"
                          value={this.state.servicer._id}
                          onChange={this.onChange}
                          options={optionsProfile}
                          error={errors.servicer}
                          disabled={this.props.profiles.profile.position === 'Serviser'}
                        />
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Opis kvara/Komentar"
                          placeholder="Opis kvara/Komentar"
                          name="desc"
                          value={this.state.desc}
                          onChange={this.onChange}
                          error={errors.descticket}
                          disabled={this.props.profiles.profile.position !== 'Admin'}
                        />
                        <div className="text-info">
                          {this.state.desc.length}/130
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis popravke/Delovi"
                          placeholder="Opis popravke/Delovi"
                          name="parts"
                          value={this.state.parts}
                          onChange={this.onChange}
                          error={errors.partsticket}
                          rows={5}
                        />
                        <div className="text-info">
                          {this.state.parts.length}/130
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Datum kreiranja:{' '}
                          {isEmpty(this.state.datecreate) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY HH:mm">
                                {this.state.datecreate}
                              </Moment>
                            </span>
                          )}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Tiket kreirao/la:{' '}
                          {isEmpty(this.state.datecreate) ? null : (
                            <span>
                              {isEmpty(ticket.user) ? null : ticket.user.name}
                            </span>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/tickets"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Tikete
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      );
    }
    return <div className="content">{ticketContent}</div>;
  }
}



Ticket.propTypes = {
  getProfilesAdmin: PropTypes.func.isRequired,
  getTicketById: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired,
  updateTicket: PropTypes.func.isRequired,
  profiles: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ticket: state.ticket,
  profiles: state.profile,
  services: state.service,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getTicketById, updateTicket, getServices, getProfilesAdmin }
)(withRouter(Ticket));
