import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

class Footer extends Component {
  render() {
    const authLinks =
      this.props.auth.user.position !== 'Klijent' ? (
        <footer className="footer">
          <Container fluid>
            <nav className="pull-left">
              <ul>
                <li>
                  <a href="/dashboard">Home</a>
                </li>
                <li>
                  <a href="/edit-profile">User Profile</a>
                </li>
                <li>
                  <a href="/devices">Uredjaji</a>
                </li>
                <li>
                  <a href="/clients">Klijenti</a>
                </li>
              </ul>
            </nav>
            <p className="copyright pull-right">
              &copy; {new Date().getFullYear()}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.galeb.com"
              >
                Galeb Electronics
              </a>
                - ticketing. Call centar : 015/367-700, e-mail : ticketing@galeb.com
            </p>
          </Container>
        </footer>
      ) : (
        <footer className="footer">
          <Container fluid>
            <nav className="pull-left">
              <ul>
                <li>
                  <a href="/dashboardclient">Home</a>
                </li>
                <li>
                  <a href="/profile-client">User Profile</a>
                </li>
                <li>
                  <a href="/ticketsclient">Tiketi</a>
                </li>
              </ul>
            </nav>
            <p className="copyright pull-right">
              &copy; {new Date().getFullYear()}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.galeb.com"
              >
                Galeb Electronics
              </a>
                - ticketing. Call centar : 015/367-700, e-mail : ticketing@galeb.com
            </p>
          </Container>
        </footer>
      );
    return authLinks;
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Footer);
