import axios from 'axios';
import {
  GET_ERRORS,
  GET_SERVICES,
  SERVICES_LOADING,
  GET_SERVICE,
  DELETE_SERVICE,
  GET_DELETED_SERVICES
} from './types';

// Create Service
export const createService = (serviceData, history) => dispatch => {
  axios
    .post('/api/services', serviceData)
    .then(res => history.push('/services'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all services
export const getServices = () => dispatch => {
  dispatch(setServicesLoading());
  axios
    .get('/api/services/all')
    .then(res =>
      dispatch({
        type: GET_SERVICES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SERVICES,
        payload: null
      })
    );
};

// Get all deleted services
export const getDeletedServices = () => dispatch => {
  dispatch(setServicesLoading());
  axios
    .get('/api/services/all/deleted')
    .then(res =>
      dispatch({
        type: GET_DELETED_SERVICES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DELETED_SERVICES,
        payload: null
      })
    );
};

// Get service by id
export const getServiceById = _id => dispatch => {
  dispatch(setServicesLoading);
  axios
    .get(`/api/services/${_id}`)
    .then(res =>
      dispatch({
        type: GET_SERVICE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SERVICE,
        payload: null
      })
    );
};

// Delete Service
export const deleteService = id => dispatch => {
  axios
    .delete(`/api/services/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_SERVICE,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Activate Service
export const activateService = id => dispatch => {
  axios
    .post(`/api/services/activate/${id}`)
    .then()
    .catch(serviceerr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Services loading
export const setServicesLoading = () => {
  return {
    type: SERVICES_LOADING
  };
};
