import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import SelectListGroup from '../common/SelectListGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createDevice } from '../../actions/deviceActions';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import { getClients, getClientsNameAndId } from '../../actions/clientActions';
import { getServices } from '../../actions/serviceActions';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';

class AddDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      part: '',
      client: '',
      service: '',
      address: '',
      city: '',
      phone: '',
      mobile: '',
      description: '',
      ibfk: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getServices();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  getClientData(){
    this.props.getClientsNameAndId(this.state.part)
  }

  onSubmit(e) {
    e.preventDefault();

    const devData = {
      client: this.state.client,
      service: this.state.service,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      description: this.state.description,
      ibfk: this.state.ibfk,
      dateoffiscal: this.state.dateoffiscal,
    };

    this.props.createDevice(devData, this.props.history);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    const { services } = this.props.service;
    const { clientsNai } = this.props.client;
    //Select options for Service

    const optionsService = services.map(service => ({
      label: service.name,
      value: service._id
    }));
    optionsService.unshift({ label: '* Selektujte servis', value: '' });
    const optionsClient = clientsNai.map(client => ({
      label: client.name,
      value: client._id
    }));

    optionsClient.unshift({ label: '* Selektujte klijenta', value: '' });
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Novi uredjaj"
                category="Podaci uradjaja"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="control-label">Pib</label>
                        <TextFieldGroup
                          type="text"
                          placeholder="pib"
                          name="part"
                          value={this.state.part}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          type='button'
                          value="pretrazi"
                          onClick={this.getClientData.bind(this)}
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px',marginTop: '28px' }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <SelectListGroup
                          labels="Klijent"
                          placeholder="Klijent"
                          name="client"
                          value={this.state.client}
                          onChange={this.onChange}
                          options={optionsClient}
                          error={errors.client}
                        />
                      </div>
                      <div className="col-md-4">
                        <SelectListGroup
                          labels="Servis"
                          placeholder="Service"
                          name="service"
                          value={this.state.service}
                          onChange={this.onChange}
                          options={optionsService}
                          error={errors.service}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Adresa"
                          placeholder="Adresa"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                          error={errors.address}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                          error={errors.city}
                        />
                      </div>

                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Telefon"
                          placeholder="Telefon"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          error={errors.phone}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Mobilni"
                          placeholder="Mobilni"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.onChange}
                          error={errors.mobile}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="SBU"
                          placeholder="Serijski broj uredjaja"
                          name="ibfk"
                          value={this.state.ibfk}
                          onChange={this.onChange}
                          error={errors.ibfk}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Datum fiskalizacije"
                          name="dateoffiscal"
                          type="date"
                          value={this.state.dateoffiscal}
                          onChange={this.onChange}
                          error={errors.dateoffiscal}
                        />
                      </div>
                      <div className="row">
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis/komentar"
                          placeholder="Opis/Komentar"
                          name="description"
                          value={this.state.description}
                          onChange={this.onChange}
                          error={errors.description}
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/devices"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Uredjaji
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddDevice.propTypes = {
  getServices: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  createDevice: PropTypes.func.isRequired,
  getClientsNameAndId: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  device: state.device,
  errors: state.errors,
  service: state.service,
  client: state.client
});

export default connect(
  mapStateToProps,
  { createDevice, getClients, getServices, getClientsNameAndId }
)(withRouter(AddDevice));
