import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import Moment from 'react-moment';
import {
  getTickets,
  deleteTicket,
  activateTicket,
  getTicketsSC,
  getTicketsUID,
  getTicketsByDate
} from '../../actions/ticketActions';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Card from '../../components/Card/Card';
import { StatsCard } from '../StatsCard/StatsCard';
import SelectListGroup from '../common/SelectListGroup';
import { Bar, Pie } from 'react-chartjs-2';
import { confirmAlert } from 'react-confirm-alert';
import ReactToExcel from 'react-html-table-to-excel'
import TextFieldGroup from '../common/TextFieldGroup';
import classname from 'classname';

class TicketTable extends Component {
  constructor() {
    super();
    this.state = {
      search: '',
      edate: '',
      cdate: '',
      whatdate: '',
    }
    this.getByDate = this.getByDate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getByDate(e) {
    e.preventDefault();
    const payload = {
      ed: this.state.edate,
      cd: this.state.cdate,
      whatDate: this.state.whatdate
    }
    this.props.getTicketsByDate(payload);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }

  onDeleteClick(id) {
    confirmAlert({
      title: 'Otkazi tiket',
      message: 'Da li sigurno zelite da otkazete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => ((this.props.deleteTicket(id), window.location.reload()))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.deleteTicket(id);
  }

  onActivateClick(id) {
    confirmAlert({
      title: 'Aktiviraj tiket',
      message: 'Da li sigurno zelite da aktivirate?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.activateTicket(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  componentDidMount() {
    if (this.props.auth.user.position === 'Admin') {
      this.props.getTickets();
    } else if (this.props.auth.user.position === 'PM') {
      this.props.getTicketsSC(this.props.auth.user.service);
    } else if (this.props.auth.user.position === 'Serviser') {
      this.props.getTicketsUID(this.props.auth.user.id);
    }
    //console.log(this.props.auth.user.position);
  }


  render() {
    const { loading } = this.props.ticket;

    let styledelete;

    if (this.props.auth.user.position === 'Admin') {
      styledelete = { width: '80px' };
    } else {
      styledelete = { width: '80px', visibility: 'hidden' };
    }
    let tickets;
    let optionService = [];
    if (this.props.ticket.tickets != undefined) {
      tickets = this.props.ticket.tickets.filter(tickets => {
        return isEmpty(this.state.search)
          ? tickets
          : isEmpty(tickets.device)
            ? null
            : tickets.device.ibfk
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1 ||
            tickets.service.name
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1 ||
            tickets.device.address
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1 ||
            tickets.device.city
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) !== -1;
      });

    }
    optionService.unshift({ label: 'Galeb servis Sabac', value: 'Galeb servis Sabac' });
    optionService.unshift({ label: 'Galeb servis Beograd', value: 'Galeb servis Beograd' });
    optionService.unshift({ label: 'Galeb servis Cacak', value: 'Galeb servis Cacak' });
    optionService.unshift({ label: 'Galeb servis Kragujevac', value: 'Galeb servis Kragujevac' });
    optionService.unshift({ label: 'Galeb servis Novi Sad', value: 'Galeb servis Novi Sad' });
    optionService.unshift({ label: 'Galeb servis Nis', value: 'Galeb servis Nis' });
    optionService.unshift({ label: '* Selektujte servis', value: '' });

    //console.log(this.props.ticket.tickets);

    let ticketItems;
    let ticketItems2;
    let aktivniTiketi = 0;
    let utokuTiketi = 0;
    let nacekanjuTiketi = 0;
    let urgentniTiketi = 0;
    let otkazaniTiketi = 0;
    let zavrseniTiketi = 0;
    if (tickets === null || loading) {
      ticketItems = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
      ticketItems2 = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      if (tickets != null) {
        ticketItems = tickets.map(ticket => {
          return ticket.status < 4
            ? ((aktivniTiketi += 1),
              (
                <tr key={ticket._id}>
                  <td>
                    {isEmpty(ticket.device)
                      ? 'Nije u bazi'
                      : ticket.device.ibfk}
                  </td>
                  <td>
                    {isEmpty(ticket.client) ? 'Nije odabrano ime' : ticket.client.name}
                  </td>
                  <td>
                    {(() => {
                      switch (ticket.priority) {
                        case 1:
                          return (
                            <span className="btn-fill btn btn-sm btn-success">
                              Low
                            </span>
                          );
                        case 2:
                          return (
                            <span className="btn-fill btn btn-sm btn-info">
                              Medium
                            </span>
                          );
                        case 3:
                          return (
                            <span className="btn-fill btn btn-sm btn-warning">
                              High
                            </span>
                          );
                        case 4:
                          return (
                            (urgentniTiketi += 1),
                            (
                              <span className="btn-fill btn btn-sm btn-danger">
                                Urgent
                              </span>
                            )
                          );
                        default:
                          return null;
                      }
                    })()}
                  </td>
                  <td>
                    {isEmpty(ticket.device) ? '' : ticket.device.address},{' '}
                    {isEmpty(ticket.device) ? '' : ticket.device.city}
                  </td>
                  <td>
                    {isEmpty(ticket.service) ? '' : ticket.service.name}
                  </td>
                  <td>
                    {isEmpty(ticket.servicer) ? '' : ticket.servicer.name}
                  </td>
                  <td>{ticket.desc}</td>
                  <td>
                    {(() => {
                      switch (ticket.status) {
                        case 5:
                          return (
                            <span className="btn-fill btn btn-sm btn-danger">
                              Otkazan
                            </span>
                          );
                        case 1:
                          return (
                            <span className="btn-fill btn btn-sm btn-info">
                              Novi
                            </span>
                          );
                        case 2:
                          return (
                            (nacekanjuTiketi += 1),
                            (
                              <span className="btn-fill btn btn-sm btn-default">
                                Na cekanju
                              </span>
                            )
                          );
                        case 3:
                          return (
                            (utokuTiketi += 1),
                            (
                              <span className="btn-fill btn btn-sm btn-warning">
                                U toku
                              </span>
                            )
                          );
                        case 4:
                          return (
                            <span className="btn-fill btn btn-sm btn-success">
                              Zavrsen
                            </span>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </td>
                  <td>
                    <Moment format="DD.MM.YYYY">{ticket.datecreate}</Moment>
                  </td>
                  <td>
                    {isEmpty(ticket.dateend) ? (
                      ''
                    ) : (
                      <Moment format="DD.MM.YYYY">{ticket.dateend}</Moment>
                    )}
                  </td>
                  <td>{isEmpty(ticket.parts) ? '' : ticket.parts}</td>
                  <td>{isEmpty(ticket.user) ? '' : ticket.user.name}</td>
                  <td>
                    <Link
                      to={`/tickets/${ticket._id}`}
                      style={{ marginRight: '3px', width: '80px' }}
                      className="btn-fill btn btn-info btn-sm"
                    >
                      Izmeni
                    </Link>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn-fill btn btn-sm btn-danger"
                      //style={styledelete}
                      onClick={this.onDeleteClick.bind(this, ticket._id)}
                    >
                      Otkazi
                    </button>
                  </td>
                </tr>
              ))
            : null;
        });


        ticketItems2 = tickets.map(ticket => {
          return ticket.status >= 4 ? (
            <tr key={ticket._id}>
              <td>
                {isEmpty(ticket.device) ? 'Nije u bazi' : ticket.device.ibfk}
              </td>
              <td>
                {isEmpty(ticket.client) ? 'Nije odabrano ime' : ticket.client.name}
              </td>
              <td>
                {(() => {
                  switch (ticket.priority) {
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Low
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Medium
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          High
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Urgent
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                {isEmpty(ticket.device) ? '' : ticket.device.address},{' '}
                {isEmpty(ticket.device) ? '' : ticket.device.city}
              </td>
              <td>
                {isEmpty(ticket.device) ? '' : ticket.service.name}
              </td>
              <td>
                {isEmpty(ticket.servicer) ? '' : ticket.servicer.name}
              </td>
              <td>{ticket.desc}</td>
              <td>
                {(() => {
                  switch (ticket.status) {
                    case 5:
                      return (
                        (otkazaniTiketi += 1),
                        (
                          <span className="btn-fill btn btn-sm btn-danger">
                            Otkazan
                          </span>
                        )
                      );
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Novi
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-default">
                          Na cekanju
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          U toku
                        </span>
                      );
                    case 4:
                      return (
                        (zavrseniTiketi += 1),
                        (
                          <span className="btn-fill btn btn-sm btn-success">
                            Zavrsen
                          </span>
                        )
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                <Moment format="DD.MM.YYYY">{ticket.datecreate}</Moment>
              </td>
              <td>
                {isEmpty(ticket.dateend) ? (
                  ''
                ) : (
                  <Moment format="DD.MM.YYYY">{ticket.dateend}</Moment>
                )}
              </td>
              <td>{isEmpty(ticket.exectime) ? '' : ticket.exectime}</td>
              <td>{isEmpty(ticket.parts) ? '' : ticket.parts}</td>
              <td>{isEmpty(ticket.user) ? '' : ticket.user.name}</td>
              <td>
                <Link
                  to={`/tickets/${ticket._id}`}
                  style={{ marginRight: '3px', width: '80px' }}
                  className="btn-fill btn btn-sm btn-info"
                >
                  Izmeni
                </Link>
              </td>
              <td>
                {ticket.status === 5 ? (
                  <button
                    type="button"
                    className="btn-fill btn btn-sm btn-success"
                    style={styledelete}
                    onClick={this.onActivateClick.bind(this, ticket._id)}
                  >
                    Aktiviraj
                  </button>
                ) : null}
              </td>
            </tr>
          ) : null;
        });
      } else {
        ticketItems = (
          <tr>
            <td>No tickets found...</td>
          </tr>
        );

        ticketItems2 = (
          <tr>
            <td>No tickets found...</td>
          </tr>
        );
      }
    }

    let labels = [
      'Aktivni: ' + aktivniTiketi,
      'Otkazani: ' + otkazaniTiketi,
      'Zavrseni: ' + zavrseniTiketi
    ];
    let series = [aktivniTiketi, otkazaniTiketi, zavrseniTiketi];
    let labelsBar = [
      'Aktivni: ' + aktivniTiketi,
      'Otkazani: ' + otkazaniTiketi,
      'Zavrseni: ' + zavrseniTiketi,
      'U toku: ' + utokuTiketi,
      'Urgentni: ' + urgentniTiketi,
      'Na cekanju: ' + nacekanjuTiketi
    ];
    let seriesBar = [
      aktivniTiketi,
      otkazaniTiketi,
      zavrseniTiketi,
      utokuTiketi,
      urgentniTiketi,
      nacekanjuTiketi
    ];
    const datajs = {
      labels: labelsBar,
      datasets: [
        {
          label: 'Tiketi po statusu',
          fill: false,
          lineTension: 0.1,
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(0, 204, 102, 0.8)',
            'rgba(0, 204, 204,0.8)',
            'rgba(255, 0, 0,0.8)',
            'rgba(255, 206, 86,0.8)'
          ],
          borderColor: 'rgba(51, 153, 255,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(51, 153, 255,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(51, 153, 255,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: seriesBar
        }
      ]
    };

    const datajsPie = {
      labels: labels,
      datasets: [
        {
          data: series,
          backgroundColor: ['#36A2EB', '#FF6384', '#00cc66'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384', '#00cc66']
        }
      ]
    };

    return (
      <Container fluid>
        <Row>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-tools text-info" />}
              statsText="Aktivni"
              statsValue={aktivniTiketi}
              statsIcon={<i className="fa fa-wrench" />}
              statsIconText="Trenutno aktivnih tiketa"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-bell text-danger" />}
              statsText="Urgentni"
              statsValue={urgentniTiketi}
              statsIcon={<i className="fa fa-bell-o" />}
              statsIconText="Trenutno urgentnih tiketa"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-clock text-success" />}
              statsText="U toku"
              statsValue={utokuTiketi}
              statsIcon={<i className="fa fa-clock-o" />}
              statsIconText="Broj tiketa koji su u toku"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-coffee text-warning" />}
              statsText="Na cekanju"
              statsValue={nacekanjuTiketi}
              statsIcon={<i className="fa fa-coffee" />}
              statsIconText="Broj tiketa na cekanju"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Link
              to="/tickets/add/add-ticket"
              className="btn btn-fill btn-info"
              style={{ marginBottom: '5px' }}
            >
              <i className="pe-7s-plus" /> Novi Tiket
            </Link>
          </Col>
          <Col md={3}>
            <Link
              to="/tickets/add/add-ticket-from-tt"
              className="btn btn-fill btn-info"
              style={{ marginBottom: '5px' }}
            >
              <i className="pe-7s-plus" /> uvezi iz TT
            </Link>
          </Col>
        </Row>
        <div className="row">
          <div className="col-md-3">
            <SelectListGroup
              placeholder="Service"
              name="service"
              value={this.state.search}
              onChange={this.updateSearch.bind(this)}
              options={optionService}
            />
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <label className="control-label" />
              <input
                className="form-control form-control-lg"
                type="text"
                placeholder="Pretraga tiketa..."
                value={this.state.search}
                onChange={this.updateSearch.bind(this)}
                style={{ marginBottom: '5px' }}
              />
            </div>
          </div>
        </div>
        <Row>
          <Col md={12}>
            <Card
              title="Informacije o aktivnim tiketima"
              category="Lista tiketa"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Uredjaj</th>
                      <th>Klijent</th>
                      <th>Prioritet</th>
                      <th>Adresa</th>
                      <th>Servis</th>
                      <th>Serviser</th>
                      <th>Opis kvara</th>
                      <th>Status</th>
                      <th>Datum kreiranja</th>
                      <th>Datum izmene</th>
                      <th>Opis popravke</th>
                      <th>Korisnik</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>{ticketItems}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-close-circle text-danger" />}
              statsText="Otkazani tiketi"
              statsValue={otkazaniTiketi}
              statsIcon={<i className="fa fa-times-circle-o" />}
              statsIconText="Broj otkazanih tiketa"
            />
          </Col>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-like2 text-success" />}
              statsText="Zavrseni tiketi"
              statsValue={zavrseniTiketi}
              statsIcon={<i className="fa fa-thumbs-up" />}
              statsIconText="Broj zavrsenih tiketa"
            />
          </Col>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-note2 text-info" />}
              statsText="Ukupno zatvorenih"
              statsValue={zavrseniTiketi + otkazaniTiketi}
              statsIcon={<i className="fa fa-thumbs-up" />}
              statsIconText="Broj zatvorenih tiketa (zavrseni i otkazani)"
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Card
              id="chartActivity"
              title="Graficki pregled tiketa"
              category="Pregled tiketa po statusu"
              stats="Trenutni podaci"
              statsIcon="fa fa-history"
              content={
                <div>
                  <Bar
                    ref="chart"
                    data={datajs}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </div>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title="Statistika tiketa"
              category="Podela tiketa po izvrsenju"
              stats="Trenutni podaci"
              statsIcon="fa fa-clock-o"
              content={
                <div>
                  <Pie
                    data={datajsPie}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                  {/* <ChartistGraph data={dataPie} type='Pie' /> */}
                </div>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ReactToExcel
              font="TimesNewRoman"
              className="btn btn-fill btn-info btn-sm"
              table="table-to-xls"
              filename={'Zatvoreni_tiketi_' + new Date().toISOString()}
              sheet="sheet 1"
              buttonText="Izvoz u .xls"
            />
            <Card
              content={
                <form>
                  <div className="row">
                    <div className="col-md-3">
                      <TextFieldGroup
                        label="Datum od"
                        type="date"
                        placeholder="YYYY-MM-DD"
                        name="cdate"
                        value={this.state.cdate}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextFieldGroup
                        label="Datum do "
                        type="date"
                        placeholder="YYYY-MM-DD"
                        name="edate"
                        value={this.state.edate}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="control-label">Koji datum</label>
                      <select
                        className={classname('form-control')}
                        name="whatdate"
                        value={this.state.whatdate}
                        onChange={this.onChange}
                      >
                        <option value="">* Po kom datumu</option>
                        <option value="datecreate">Kreiranja</option>
                        <option value="dateend">Zavrešetka</option>
                      </select>
                    </div>
                    <input
                      type="submit"
                      value="Pretraži"
                      onClick={this.getByDate}
                      className="btn-fill col-md-2 pull-center btn btn-info"
                      style={{ marginTop: '21px', height: '45px' }}
                    />
                  </div>
                </form>
              }
            />
            <Card
              title="Informacije o zavrsenim i otkazanim tiketima"
              category="Lista tiketa"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover id="table-to-xls">
                  <thead>
                    <tr>
                      <th>Uredjaj</th>
                      <th>Klijent</th>
                      <th>Prioritet</th>
                      <th>Adresa</th>
                      <th>Servis</th>
                      <th>Serviser</th>
                      <th>Opis kvara</th>
                      <th>Status</th>
                      <th>Datum kreiranja</th>
                      <th>Datum zatvaranja</th>
                      <th>Utroseno vreme (Days_HH:MM)</th>
                      <th>Opis popravke</th>
                      <th>Korisnik</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>{ticketItems2}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>{' '}
      </Container>
    );
  }
}

TicketTable.propTypes = {
  getTickets: PropTypes.func.isRequired,
  deleteTicket: PropTypes.func.isRequired,
  activateTicket: PropTypes.func.isRequired,
  getTicketsUID: PropTypes.func.isRequired,
  getTicketsSC: PropTypes.func.isRequired,
  getTicketsByDate: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ticket: state.ticket,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getTickets, deleteTicket, activateTicket, getTicketsSC, getTicketsUID, getTicketsByDate }
)(TicketTable);
