import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerClientSelf } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Row, Container, Col } from 'react-bootstrap';
import Card from '../Card/Card';

class RegisterClientSelf extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      pib: '',
      phone: '',
      branch: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      pib: this.state.pib,
      phone: this.state.phone,
      branch: this.state.branch
    };
    
    this.props.registerClientSelf(newUser, this.props.history);
    console.log(this.state.errors);
  }

  componentDidMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    
    
    return (
      <div className="content">
        <Container fluid>
          <Row style={{ paddingTop: '5%' }}>
            <Col md={5} className="pull-center">
            <Card
              content={
                <div>
                  <h1 className="display-4 text-center">Registracija</h1>
                  <p className="lead text-center">
                    Registrujte se za svoj GalebTicketing nalog
                  </p>
              <div>
                <form noValidate onSubmit={this.onSubmit}>
                  <TextFieldGroup
                    label="Ime i prezime"
                    placeholder="Ime i Prezime"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    error={errors.nameclient}
                  />
                  <TextFieldGroup
                    label="Email Adresa"
                    placeholder="Email Adresa"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={errors.emailclient}
                  />
                  <TextFieldGroup
                    label="Lozinka"
                    placeholder="Lozinka"
                    name="password"
                    type="password"
                    value={this.state.password} 
                    onChange={this.onChange}
                    error={errors.passwordclient}
                  />
                  <TextFieldGroup
                    label="Potvrdi lozinku"
                    placeholder="Potvrdi lozinku"
                    name="password2"
                    type="password"
                    value={this.state.password2}
                    onChange={this.onChange}
                    error={errors.password2client}
                  />
                  <TextFieldGroup
                    label="PIB preduzeća"
                    placeholder="PIB preduzeća"
                    name="pib"
                    value={this.state.pib}
                    onChange={this.onChange}
                    error={errors.clientclient}
                  />
                  <TextFieldGroup
                    label="Telefon"
                    placeholder="Telefon"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChange}
                    error={errors.phoneclient}
                  />
                  <TextFieldGroup
                    label="Adresa"
                    placeholder="Adresa"
                    name="branch"
                    value={this.state.branch}
                    onChange={this.onChange}
                    error={errors.branchclient}
                  />
                  <input
                    type="submit"
                    value="Registruj korisnika"
                    className="btn btn-fill btn-info btn-block mt-4"
                  />
                    </form>
                  </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

RegisterClientSelf.propTypes = {
  registerClientSelf: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerClientSelf }
)(withRouter(RegisterClientSelf));
