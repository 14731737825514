import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../Card/Card';
import { StatsCard } from '../StatsCard/StatsCard';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Spinner from '../common/Spinner';
import { deleteTicket } from '../../actions/ticketActions';
import { Bar, Pie } from 'react-chartjs-2';
import { confirmAlert } from 'react-confirm-alert';

class TicketsTableClient extends Component {
  constructor() {
    super();
    this.state = {
      search: ''
    };
  }

  onDeleteClick(id) {
    confirmAlert({
      title: 'Otkazi tiket',
      message: 'Da li ste sigurni da zelite otkazete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => ((this.props.deleteTicket(id), window.location.reload()))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }
  render() {
    const { loading } = this.props.device;
    let devices = this.props.device.devices.filter(devices => {
      return devices.ticketlist.length > 0;
    });
    devices = devices.filter(device => {
      return (
        device.ibfk.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1 ||
        device.address.toLowerCase().indexOf(this.state.search.toLowerCase()) !== 
          -1 ||
        device.city.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1
      );
    });
    let ticketItems;
    let ticketItems2;
    let aktivniTiketi = 0;
    let utokuTiketi = 0;
    let nacekanjuTiketi = 0;
    let urgentniTiketi = 0;
    let otkazaniTiketi = 0;
    let zavrseniTiketi = 0;
    if (devices === null || loading) {
      ticketItems = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
      ticketItems2 = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      ticketItems = devices.map(device => {
        return device.ticketlist.map(ticket => {
          return ticket.ticketid.status < 4
            ? ((aktivniTiketi += 1),
              (
                <tr key={ticket._id}>
                  <td>{isEmpty(device) ? 'Nije u bazi' : device.ibfk}</td>
                  <td>
                    {(() => {
                      switch (ticket.ticketid.priority) {
                        case 1:
                          return (
                            <span className="btn-fill btn btn-sm btn-success">
                              Low
                            </span>
                          );
                        case 2:
                          return (
                            <span className="btn-fill btn btn-sm btn-info">
                              Medium
                            </span>
                          );
                        case 3:
                          return (
                            <span className="btn-fill btn btn-sm btn-warning">
                              High
                            </span>
                          );
                        case 4:
                          return (
                            (urgentniTiketi += 1),
                            (
                              <span className="btn-fill btn btn-sm btn-danger">
                                Urgent
                              </span>
                            )
                          );
                        default:
                          return null;
                      }
                    })()}
                  </td>
                  <td>
                    {isEmpty(device) ? '' : device.address},{' '}
                    {isEmpty(device) ? '' : device.city}
                  </td>
                  <td>{isEmpty(device) ? '' : device.service.name}</td>
                  <td>{ticket.ticketid.desc}</td>
                  <td>
                    {(() => {
                      switch (ticket.ticketid.status) {
                        case 5:
                          return (
                            <span className="btn-fill btn btn-sm btn-danger">
                              Otkazan
                            </span>
                          );
                        case 1:
                          return (
                            <span className="btn-fill btn btn-sm btn-info">
                              Novi
                            </span>
                          );
                        case 2:
                          return (
                            (nacekanjuTiketi += 1),
                            (
                              <span className="btn-fill btn btn-sm btn-default">
                                Na cekanju
                              </span>
                            )
                          );
                        case 3:
                          return (
                            (utokuTiketi += 1),
                            (
                              <span className="btn-fill btn btn-sm btn-warning">
                                U toku
                              </span>
                            )
                          );
                        case 4:
                          return (
                            <span className="btn-fill btn btn-sm btn-success">
                              Zavrsen
                            </span>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </td>
                  <td>
                    <Moment format="DD.MM.YYYY">
                      {ticket.ticketid.datecreate}
                    </Moment>
                  </td>
                  <td>
                    {isEmpty(ticket.ticketid.dateend) ? (
                      ''
                    ) : (
                      <Moment format="DD.MM.YYYY">
                        {ticket.ticketid.dateend}
                      </Moment>
                    )}
                  </td>
                  <td>
                    {isEmpty(ticket.ticketid.parts)
                      ? ''
                      : ticket.ticketid.parts}
                  </td>
                  <td>
                    <button
                      type="button"
                      hidden
                      className="btn-fill btn btn-sm btn-danger"
                      //style={styledelete}
                      onClick={this.onDeleteClick.bind(
                        this,
                        ticket.ticketid._id
                      )}
                    >
                      Otkazi
                    </button>
                  </td>
                </tr>
              ))
            : null;
        });
      });

      ticketItems2 = devices.map(device => {
        return device.ticketlist.map(ticket => {
          return ticket.ticketid.status >= 4 ? (
            <tr key={ticket._id}>
              <td>{isEmpty(device) ? 'Nije u bazi' : device.ibfk}</td>
              <td>
                {(() => {
                  switch (ticket.ticketid.priority) {
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Low
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Medium
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          High
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Urgent
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                {isEmpty(device) ? '' : device.address},{' '}
                {isEmpty(device) ? '' : device.city}
              </td>
              <td>{isEmpty(device) ? '' : device.service.name}</td>
              <td>{ticket.ticketid.desc}</td>
              <td>
                {(() => {
                  switch (ticket.ticketid.status) {
                    case 5:
                      return (
                        (otkazaniTiketi += 1),
                        (
                          <span className="btn-fill btn btn-sm btn-danger">
                            Otkazan
                          </span>
                        )
                      );
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Novi
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-default">
                          Na cekanju
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          U toku
                        </span>
                      );
                    case 4:
                      return (
                        (zavrseniTiketi += 1),
                        (
                          <span className="btn-fill btn btn-sm btn-success">
                            Zavrsen
                          </span>
                        )
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                <Moment format="DD.MM.YYYY">
                  {ticket.ticketid.datecreate}
                </Moment>
              </td>
              <td>
                {isEmpty(ticket.ticketid.dateend) ? (
                  ''
                ) : (
                  <Moment format="DD.MM.YYYY">{ticket.ticketid.dateend}</Moment>
                )}
              </td>
              <td>
                {isEmpty(ticket.ticketid.parts) ? '' : ticket.ticketid.parts}
              </td>
            </tr>
          ) : null;
        });
      });
    }
    let labels = [
      'Aktivni: ' + aktivniTiketi,
      'Otkazani: ' + otkazaniTiketi,
      'Zavrseni: ' + zavrseniTiketi
    ];
    let series = [aktivniTiketi, otkazaniTiketi, zavrseniTiketi];
    let labelsBar = [
      'Aktivni: ' + aktivniTiketi,
      'Otkazani: ' + otkazaniTiketi,
      'Zavrseni: ' + zavrseniTiketi,
      'U toku: ' + utokuTiketi,
      'Urgentni: ' + urgentniTiketi,
      'Na cekanju: ' + nacekanjuTiketi
    ];
    let seriesBar = [
      aktivniTiketi,
      otkazaniTiketi,
      zavrseniTiketi,
      utokuTiketi,
      urgentniTiketi,
      nacekanjuTiketi
    ];
    const datajs = {
      labels: labelsBar,
      datasets: [
        {
          label: 'Tiketi po statusu',
          fill: false,
          lineTension: 0.1,
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(0, 204, 102, 0.8)',
            'rgba(0, 204, 204,0.8)',
            'rgba(255, 0, 0,0.8)',
            'rgba(255, 206, 86,0.8)'
          ],
          borderColor: 'rgba(51, 153, 255,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(51, 153, 255,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(51, 153, 255,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: seriesBar
        }
      ]
    };
    const datajsPie = {
      labels: labels,
      datasets: [
        {
          data: series,
          backgroundColor: ['#36A2EB', '#FF6384', '#00cc66'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384', '#00cc66']
        }
      ]
    };

    return (
      <Container fluid>
        <Row>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-tools text-info" />}
              statsText="Aktivni"
              statsValue={aktivniTiketi}
              statsIcon={<i className="fa fa-wrench" />}
              statsIconText="Trenutno aktivnih tiketa"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-bell text-danger" />}
              statsText="Urgentni"
              statsValue={urgentniTiketi}
              statsIcon={<i className="fa fa-bell-o" />}
              statsIconText="Trenutno urgentnih tiketa"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-clock text-success" />}
              statsText="U toku"
              statsValue={utokuTiketi}
              statsIcon={<i className="fa fa-clock-o" />}
              statsIconText="Broj tiketa koji su u toku"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-coffee text-warning" />}
              statsText="Na cekanju"
              statsValue={nacekanjuTiketi}
              statsIcon={<i className="fa fa-coffee" />}
              statsIconText="Broj tiketa na cekanju"
            />
          </Col>
        </Row>
        <Link
          to="/ticketsclient/add/add-ticket"
          className="btn btn-fill btn-info btn-sm"
          style={{ marginBottom: '5px' }}
        >
          <i className="pe-7s-plus" /> Novi Tiket
        </Link>
        
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="control-label" />
              <input
                className="form-control form-control-lg"
                type="text"
                placeholder="Pretraga tiketa..."
                value={this.state.search}
                onChange={this.updateSearch.bind(this)}
                style={{ marginBottom: '5px' }}
              />
            </div>
          </div>
        </div>
        <Row>
          <Col md={12}>
            <Card
              title="Informacije o aktivnim tiketima"
              category="Lista aktivnih tiketa"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Uredjaj</th>
                      <th>Prioritet</th>
                      <th>Adresa</th>
                      <th>Servis</th>
                      <th>opis kvara</th>
                      <th>Status</th>
                      <th>datum kreiranja</th>
                      <th>datum izmene</th>
                      <th>opis popravke</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{ticketItems}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>{' '}
        <Row>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-close-circle text-danger" />}
              statsText="Otkazani tiketi"
              statsValue={otkazaniTiketi}
              statsIcon={<i className="fa fa-times-circle-o" />}
              statsIconText="Broj otkazanih tiketa"
            />
          </Col>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-like2 text-success" />}
              statsText="Zavrseni tiketi"
              statsValue={zavrseniTiketi}
              statsIcon={<i className="fa fa-thumbs-up" />}
              statsIconText="Broj zavrsenih tiketa"
            />
          </Col>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-note2 text-info" />}
              statsText="Ukupno zatvorenih"
              statsValue={zavrseniTiketi + otkazaniTiketi}
              statsIcon={<i className="fa fa-thumbs-up" />}
              statsIconText="Broj zatvorenih tiketa (zavrseni i otkazani)"
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Card
              id="chartActivity"
              title="Graficki pregled tiketa"
              category="Pregled tiketa po statusu"
              stats="Trenutni podaci"
              statsIcon="fa fa-history"
              content={
                <div>
                  {/* <ChartistGraph
                  data={dataBar}
                  
                  type='Line'
                  options={optionsBar}
                  responsiveOptions={responsiveBar}
                /> */}
                  <Bar
                    ref="chart"
                    data={datajs}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </div>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title="Statistika tiketa"
              category="Podela tiketa po izvrsenju"
              stats="Trenutni podaci"
              statsIcon="fa fa-clock-o"
              content={
                <div>
                  <Pie
                    data={datajsPie}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                  {/* <ChartistGraph data={dataPie} type='Pie' /> */}
                </div>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title="Informacije o zavrsenim i otkazanim tiketima"
              category="Lista tiketa"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Uredjaj</th>
                      <th>Prioritet</th>
                      <th>Adresa</th>
                      <th>Servis</th>
                      <th>opis kvara</th>
                      <th>Status</th>
                      <th>datum kreiranja</th>
                      <th>datum zatvaranja</th>
                      <th>opis popravke</th>
                    </tr>
                  </thead>
                  <tbody>{ticketItems2}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>{' '}
      </Container>
    );
  }
}

TicketsTableClient.propTypes = {
  deleteTicket: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { deleteTicket }
)(TicketsTableClient);
