import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextFieldGroup from '../common/TextFieldGroup';
import { getCurrentProfile, passwordReset } from '../../actions/profileAction';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card.jsx';
import { UserCard } from '../../components/UserCard/UserCard.jsx';

class EditProfileClient extends Component {
  constructor(props) {
    super();
    this.state = {
      phone: '',
      branch: '',
      service: '',
      oldpassword: '',
      password: '',
      password2: '',
      email: '',
      errors: {}
    };
    this.onPasswordReset = this.onPasswordReset.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile) {
      const profile = nextProps.profile.profile;

      profile.phone = !isEmpty(profile.phone) ? profile.phone : '';
      profile.branch = !isEmpty(profile.branch) ? profile.branch : '';
      profile.service = !isEmpty(profile.service) ? profile.service : '';
      profile.email = !isEmpty(profile.email) ? profile.email : '';
      profile.oldpassword = !isEmpty(profile.oldpassword) ? profile.oldpassword : '';
      profile.password = !isEmpty(profile.password) ? profile.password : '';
      profile.password2 = !isEmpty(profile.password2) ? profile.password2 : '';

      // Set component fields state
      this.setState({
        phone: profile.phone,
        branch: profile.branch,
        service: profile.service,
        email: profile.email
      });
    }
  }

  onPasswordReset(e) {
    e.preventDefault();
    const profileData2 = {
      email: this.state.email,
      service: this.state.service,
      oldpassword: this.state.oldpassword,
      password: this.state.password,
      password2: this.state.password2
    };
    this.props.passwordReset(profileData2, this.props.history);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    // pristupamo errors preko state jer je u componentWillReceiveProps
    // setovan state
    const { user } = this.props.auth;
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Informacije o korisniku"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Ime i prezime"
                          name="name"
                          value={user.name}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Kontakt telefon"
                          placeholder="* Telefon"
                          name="phone"
                          value={this.state.phone}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-3">
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Adresa"
                          placeholder="Ogranak"
                          name="branch"
                          value={this.state.branch}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Email adresa"
                          placeholder="Email"
                          name="email"
                          value={this.state.email}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Pozicija"
                          name="position"
                          value={user.position}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <UserCard
                bgImage="https://www.ambientum.com/wp-content/uploads/2018/08/via-lactea.jpg"
                avatar={user.avatar}
                name={user.name}
                userName={this.state.email}
                description={
                  <span>
                    {this.state.phone}
                    <br />
                    {this.state.branch}
                    <br />
                  </span>
                }
                socials={
                  <div className="row">
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
          </Row>
        </Container>
      </div>
    );
  }
}

EditProfileClient.propTypes = {
  passwordReset: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, passwordReset }
)(withRouter(EditProfileClient));
