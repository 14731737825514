import axios from 'axios';

import {
  GET_PROFILE,
  PROFILE_LOADING,
  PROFILES_LOADING,
  GET_ERRORS,
  CLEAR_CURRENT_PROFILE,
  GET_PROFILES,
  DELETE_PROFILE
} from './types';

// Get current profile
export const getCurrentProfile = () => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get('/api/users/current')
    .then(res =>
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILE,
        payload: {}
      })
    );
};

// Get client by id
export const getProfileById = id => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get(`/api/users/${id}`)
    .then(res =>
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILE,
        payload: null
      })
    );
};

// Get all servicers
export const getProfiles = () => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get('/api/users/all')
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

//Get user Admin
export const getProfilesAdmin = () => dispatch => {
  dispatch(setProfilesLoading());
  axios
    .get('/api/users/admin')
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

//Get client users
export const getProfilesClient = () => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get('/api/users/client') 
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};

// Create Profile
export const createProfile = (profileData, history) => dispatch => {
  axios
    .post('/api/users/update', profileData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Update Profile
export const updateProfile = (profileData, history) => dispatch => {
  axios
    .post('/api/users/updatecurrent', profileData)
    .then(res => history.push('/profiles'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Password Reset
export const passwordReset = (profileData, history) => dispatch => {
  axios
    .post('/api/users/password', profileData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Password Reset by id
export const passwordResetID = (profileData, history) => dispatch => {
  axios
    .post(`/api/users/passwordid`, profileData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete User
export const deleteUser = id => dispatch => {
  axios
    .delete(`/api/users/${id}`)
    .then(res =>
      dispatch({ 
        type: DELETE_PROFILE,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Activate User
export const activateUser = id => dispatch => {
  axios
    .post(`/api/users/activate/${id}`)
    .then()
    .catch(usererr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Deactivate User
export const deactivateUser = id => dispatch => {
  axios
    .post(`/api/users/deactivate/${id}`)
    .then()
    .catch(usererr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Profile loading
export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING
  };
};

// Profiles loading
export const setProfilesLoading = () => {
  return {
    type: PROFILES_LOADING
  };
};

// Clear profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};
