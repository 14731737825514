import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getDeviceById, annualDevice } from '../../actions/deviceActions';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import Moment from 'react-moment';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';

class AnnualService extends Component {
  constructor(props) {
    super();
    this.state = {
      nextannual: '',
      date: '',
      description: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.device.device) {
      const device = nextProps.device.device;

      device.nextannual = !isEmpty(device.nextannual) ? device.nextannual : '';
      device.description = !isEmpty(device.description)
        ? device.description
        : '';

      // Set component fields state
      this.setState({
        nextannual: device.nextannual
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const deviceData = {
      _id: this.props.match.params._id,
      date: this.state.date,
      nextannual: this.state.nextannual,
      description: this.state.description
    };

    this.props.annualDevice(deviceData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params._id) {
      this.props.getDeviceById(this.props.match.params._id);
    }
  }

  render() {
    const { errors } = this.state;
    const { device, loading } = this.props.device;
    let deviceContent;
    if (device === null || loading) {
      deviceContent = <Spinner />;
    } else {
      deviceContent = (
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Tehnicki pregled"
                category={
                  'Ogranak: ' + device.branch + ' Uredjaj: ' + device.ibfk
                }
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <h6>Datum zakazanog tehnickog</h6>
                        <h6 className="form-control form-control">
                          {isEmpty(device.nextannual) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY">
                                {device.nextannual}
                              </Moment>
                            </span>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Datum izvrsenja tehnickog"
                          name="date"
                          type="date"
                          value={this.state.date}
                          onChange={this.onChange}
                          error={errors.dateannual}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Sledeci tehnicki"
                          name="nextannual"
                          type="date"
                          value={this.state.nextannual}
                          onChange={this.onChange}
                          error={errors.nextannual}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis/komentar"
                          placeholder="Opis/Komentar"
                          name="description"
                          value={this.state.description}
                          onChange={this.onChange}
                          error={errors.description}
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/devices"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Uredjaji
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      );
    }

    return <div className="content">{deviceContent}</div>;
  }
}
AnnualService.propTypes = {
  annualDevice: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  device: state.device,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getDeviceById, annualDevice }
)(withRouter(AnnualService));
