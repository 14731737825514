import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDevicesByClient } from '../../actions/deviceActions';
import TicketsTableClient from './TicketsTableClient';

class TicketsClient extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.getDevicesByClient({ client: this.props.auth.user.client });
  }

  render() {
    
    return (
      <div className="content">
        <TicketsTableClient device={this.props.device} />
      </div>
    );
  }
}
TicketsClient.propTypes = {
  getDevicesByClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  device: state.device
});

export default connect(
  mapStateToProps,
  { getDevicesByClient }
)(TicketsClient);
