import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import ProfileItem from './ProfileItem';
import { getProfilesAdmin } from '../../actions/profileAction';
import { Container, Row, Col } from 'react-bootstrap';

class Profiles extends Component {
  componentDidMount() {
    this.props.getProfilesAdmin();
  }

  render() {
    const { profiles, loading } = this.props.profile;
    let profileItems;

    
    if (profiles === null || loading) {
      profileItems = <Spinner />;
    } else {
      if (profiles.length > 0) {
        profileItems = profiles.map(profile => (
          <Col key={profile._id} md={4}>
            <ProfileItem key={profile._id} profile={profile} />
          </Col>
        ));
      } else {
        profileItems = <h4>No profiles found...</h4>;
      }
    }

    return (
      <div className="content">
        <Container fluid>
          <Row>{profileItems}</Row>
        </Container>
      </div>
    );
  }
}

Profiles.propTypes = {
  getProfilesAdmin: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getProfilesAdmin }
)(Profiles);
