import {
  GET_SERVICES,
  SERVICES_LOADING,
  DELETE_SERVICE,
  GET_SERVICE,
  GET_DELETED_SERVICES
} from '../actions/types';

const initialState = {
  services: [],
  deletedservices: [],
  service: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SERVICES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
        loading: false
      };
    case GET_SERVICE:
      return {
        ...state,
        service: action.payload,
        loading: false
      };
    case DELETE_SERVICE:
      return {
        ...state,
        services: state.services.filter(
          service => service._id !== action.payload
        )
      };
    case GET_DELETED_SERVICES:
      return {
        ...state,
        deletedservices: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
