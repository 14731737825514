// import Dashboard from "views/Dashboard/Dashboard";
// import UserProfile from "views/UserProfile/UserProfile";
// import TableList from "views/TableList/TableList";
// import Typography from "views/Typography/Typography";
// import Icons from "views/Icons/Icons";
// import Maps from "views/Maps/Maps";
// import Notifications from "views/Notifications/Notifications";
// import Upgrade from "views/Upgrade/Upgrade";

const dashboardRoutes = [
  { path: '/dashboard', name: 'Dashboard', icon: 'pe-7s-graph' },
  { path: '/edit-profile', name: 'Profil', icon: 'pe-7s-user' },
  { path: '/devices', name: 'Uredjaji', icon: 'pe-7s-usb' },
  { path: '/clients', name: 'Klijenti', icon: 'pe-7s-users' },
  { path: '/services', name: 'Servisi', icon: 'pe-7s-tools' },
  { path: '/profiles', name: 'Admin/PM/Serviseri', icon: 'pe-7s-id' },
  { path: '/tickets', name: 'Tiketi', icon: 'pe-7s-note2' },
  //{ path: '/requests', name: 'Ostali zahtevi', icon: 'pe-7s-note2' },
  { redirect: true, path: '/', to: '/login', name: 'Login' }
];

export default dashboardRoutes;
