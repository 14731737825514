import axios from 'axios';
import {
  GET_ERRORS,
  REQUESTS_LOADING,
  GET_REQUESTS,
  GET_REQUEST,
  DELETE_REQUEST
} from './types';
import { setTicketLoading } from './ticketActions';

// Create Request
export const createRequest = (requestData, history) => dispatch => {
  axios
    .post('/api/requests/create', requestData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all requests
export const getRequests = () => dispatch => {
  dispatch(setRequestLoading());
  axios
    .get('/api/requests')
    .then(res =>
      dispatch({
        type: GET_REQUESTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_REQUESTS,
        palyoad: null
      })
    );
};

// Get request by id
export const getRequestById = _id => dispatch => {
  dispatch(setTicketLoading());
  axios
    .get(`/api/requests/${_id}`)
    .then(res =>
      dispatch({
        type: GET_REQUEST,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_REQUEST,
        payload: null
      })
    );
};

// Update Request
export const updateRequest = (request_id, requestData, history) => dispatch => {
  axios
    .post(`/api/requests/update/${request_id}`, requestData)
    .then(res => history.push('/requests'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete Request
export const deleteRequest = id => dispatch => {
  axios
    .delete(`/api/requests/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_REQUEST,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        palyoad: err.response.data
      })
    );
};

// Activate Request
export const activateRequest = id => dispatch => {
  axios
    .post(`/api/requests/activate/${id}`)
    .then()
    .catch(requesterr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Get all requests by client
export const getRequestsByClient = (requestData, history) => dispatch => {
  dispatch(setRequestLoading());
  axios
    .post('/api/requests/requestsbyclient', requestData)
    .then(res =>
      dispatch({
        type: GET_REQUESTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_REQUESTS,
        payload: null
      })
    );
};

// Requests loading
export const setRequestLoading = () => {
  return {
    type: REQUESTS_LOADING
  };
};
