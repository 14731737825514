import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import Moment from 'react-moment';
import {
  getRequests,
  activateRequest,
  deleteRequest
} from '../../actions/requestAction';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Card from '../../components/Card/Card';
import { confirmAlert } from 'react-confirm-alert';

class RequestsTable extends Component {
  constructor() {
    super();
    this.state = {
      search: ''
    };
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }

  componentDidMount() {
    this.props.getRequests();
  }

  onDeleteClick(id) {
    confirmAlert({
      title: 'Otkazi zahtev',
      message: 'Da li sigurno zelite da otkazete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.deleteRequest(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  onActivateClick(id) {
    confirmAlert({
      title: 'Aktiviraj zahtev',
      message: 'Da li sigurno zelite da aktivirate?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (
            (this.props.activateRequest(id), window.location.reload())
          )
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const { loading } = this.props.request;

    const requests = this.props.request.requests.filter(requests => {
      return (
        requests.device
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        requests.address
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        requests.city.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
          -1
      );
    });

    let requestItems;
    let requestItems2;

    if (requests === null || loading) {
      requestItems = (
        <tr>
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );

      requestItems2 = (
        <tr>
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      if (requests.length > 0) {
        requestItems = requests.map(request => {
          return request.status < 4 ? (
            <tr key={request._id}>
              <td>{request.device}</td>
              <td>
                {(() => {
                  switch (request.priority) {
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Low
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Medium
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          High
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Urgent
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                {isEmpty(request.address) ? '' : request.address},{' '}
                {isEmpty(request.city) ? '' : request.city}
              </td>
              <td>{request.desc}</td>
              <td>
                {(() => {
                  switch (request.status) {
                    case 5:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Otkazan
                        </span>
                      );
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Novi
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-default">
                          Na cekanju
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          U toku
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Zavrsen
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                <Moment format="DD.MM.YYYY">{request.datecreate}</Moment>
              </td>
              <td>
                <Link
                  to={`/requests/${request._id}`}
                  style={{ marginRight: '3px', width: '80px' }}
                  className="btn-fill btn btn-sm btn-info">
                  Izmeni
                </Link>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-fill btn btn-sm btn-danger"
                  onClick={this.onDeleteClick.bind(this, request._id)}>
                  Otkazi
                </button>
              </td>
            </tr>
          ) : null;
        });

        requestItems2 = requests.map(request => {
          return request.status >= 4 ? (
            <tr key={request._id}>
              <td>{request.device}</td>
              <td>
                {(() => {
                  switch (request.priority) {
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Low
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Medium
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          High
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Urgent
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                {isEmpty(request.address) ? '' : request.address},{' '}
                {isEmpty(request.city) ? '' : request.city}
              </td>
              <td>{request.desc}</td>
              <td>
                {(() => {
                  switch (request.status) {
                    case 5:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Otkazan
                        </span>
                      );
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Novi
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-default">
                          Na cekanju
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          U toku
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Zavrsen
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                <Moment format="DD.MM.YYYY">{request.datecreate}</Moment>
              </td>
              <td>
                <Link
                  to={`/requests/${request._id}`}
                  style={{ marginRight: '3px', width: '80px' }}
                  className="btn-fill btn btn-sm btn-info"
                >
                  Izmeni
                </Link>
              </td>
              <td>
                {request.status === 5 ? (
                  <button
                    type="button"
                    hidden
                    className="btn-fill btn btn-sm btn-success"
                    //style={styledelete}
                    onClick={this.onActivateClick.bind(this, request._id)}
                  >
                    Aktiviraj
                  </button>
                ) : null}
              </td>
            </tr>
          ) : null;
        });
      } else {
        requestItems = (
          <tr>
            <td>Trenutno nema zahteva...</td>
          </tr>
        );

        requestItems2 = (
          <tr>
            <td>Trenutno nema zahteva...</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <Container fluid>
          <Link
            to="/requests/add/add-request"
            className="btn btn-fill btn-info btn-sm"
            style={{ marginBottom: '5px' }}
          >
            <i className="pe-7s-plus" /> Novi Zahtev
          </Link>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label" />
                <input
                  className="form-control form-control-lg"
                  type="text"
                  placeholder="Pretraga zahteva..."
                  value={this.state.search}
                  onChange={this.updateSearch.bind(this)}
                  style={{ marginBottom: '5px' }}
                />
              </div>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <Card
                title="Informacije o aktivnim zahtevima"
                category="Lista zahteva"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Uredjaj</th>
                        <th>Prioritet</th>
                        <th>Adresa</th>
                        <th>Opis</th>
                        <th>Status</th>
                        <th>datum kreiranja</th>
                      </tr>
                    </thead>
                    <tbody>{requestItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Informacije o zavrsenim i otkazanim zahtevima"
                category="Lista zahteva"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Uredjaj</th>
                        <th>Prioritet</th>
                        <th>Adresa</th>
                        <th>Opis</th>
                        <th>Status</th>
                        <th>datum kreiranja</th>
                      </tr>
                    </thead>
                    <tbody>{requestItems2}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

RequestsTable.propTypes = {
  getRequests: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  activateRequest: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  request: state.request,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getRequests, activateRequest, deleteRequest }
)(RequestsTable);
