import React, { Component } from 'react';
import ClientTable from '../clients/ClientTable';

class Clients extends Component {
  render() {
    return (
      <div className="content">
        <ClientTable />
      </div>
    );
  }
}

export default Clients;
