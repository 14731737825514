import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { getDeviceById, updateDevice } from '../../actions/deviceActions';
import { getServices } from '../../actions/serviceActions';
import { getClients } from '../../actions/clientActions';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import SelectListGroup from '../common/SelectListGroup';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card.jsx';
import TicketHistory from './TicketHistory';

class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      service: '',
      branch: '',
      address: '',
      city: '',
      phone: '',
      mobile: '',
      description: '',
      devicemodel: '',
      ibfk: '',
      ibfm: '',
      dateoffiscal: '',
      nextannual: '',
      annualservices: [],
      ticketlist: [],
      //vendor: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getServices();
    this.props.getClients();
  }

  onSubmit(e) {
    e.preventDefault();
    const newDev = {
      client: this.state.client,
      service: this.state.service,
      branch: this.state.branch,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      description: this.state.description,
      devicemodel: this.state.devicemodel,
      ibfk: this.state.ibfk,
      ibfm: this.state.ibfm,
      dateoffiscal: this.state.dateoffiscal,
      nextannual: this.state.nextannual
    };

    this.props.updateDevice(this.state._id, newDev, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.device.device) {
      const device = nextProps.device.device;
      device._id = !isEmpty(device._id) ? device._id : '';
      device.client = !isEmpty(device.client) ? device.client : '';
      device.service = !isEmpty(device.service) ? device.service : '';
      device.branch = !isEmpty(device.branch) ? device.branch : '';
      device.address = !isEmpty(device.address) ? device.address : '';
      device.city = !isEmpty(device.city) ? device.city : '';
      device.phone = !isEmpty(device.phone) ? device.phone : '';
      device.mobile = !isEmpty(device.mobile) ? device.mobile : '';
      device.description = !isEmpty(device.description)
        ? device.description
        : '';
      device.devicemodel = !isEmpty(device.devicemodel)
        ? device.devicemodel
        : '';
      device.ibfk = !isEmpty(device.ibfk) ? device.ibfk : '';
      device.ibfm = !isEmpty(device.ibfm) ? device.ibfm : '';
      device.dateoffiscal = !isEmpty(device.dateoffiscal)
        ? device.dateoffiscal
        : '';
      device.nextannual = !isEmpty(device.nextannual) ? device.nextannual : '';

      // Set component fields state
      this.setState({
        _id: device._id,
        client: device.client,
        service: device.service,
        branch: device.branch,
        address: device.address,
        city: device.city,
        phone: device.phone,
        mobile: device.mobile,
        description: device.description,
        devicemodel: device.devicemodel,
        ibfk: device.ibfk,
        ibfm: device.ibfm,
        dateoffiscal: device.dateoffiscal,
        nextannual: device.nextannual,
        annualservices: device.annualservices,
        ticketlist: device.ticketlist,
        //vendor: device.vendor
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params._id) {
      this.props.getDeviceById(this.props.match.params._id);

    }
  }

  render() {
    const { errors } = this.state;
    const { device, loading } = this.props.device;
    const { services } = this.props.services;
    const { clients } = this.props.client;
    //Select options for Service
    const optionsService = services.map(service => ({
      label: service.name,
      value: service._id
    }));
    optionsService.unshift({ label: '* Selektujte servis', value: '' });


    const optionsClient = clients.map(client => ({
      label: client.name,
      value: client._id
    }));
    optionsClient.unshift({ label: '* Selektujte klijenta', value: '' });
    let deviceContent;
    if (device === null || loading) {
      deviceContent = <Spinner />;
    } else {
      deviceContent = (
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.state.ibfk}
                category="Podaci uredjaja"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <SelectListGroup
                          labels="Klijent"
                          placeholder="Klijent"
                          name="client"
                          value={this.state.client}
                          options={optionsClient}
                          error={errors.client}
                          disabled="disabled"
                        />
                      </div>
                      <div className="col-md-4">
                        <SelectListGroup
                          labels="Servis"
                          placeholder="Service"
                          name="service"
                          value={this.state.service}
                          onChange={this.onChange}
                          options={optionsService}
                          error={errors.service}
                        />
                      </div>
                      {/*<div className="col-md-4">
                        <TextFieldGroup
                          label="Ogranak"
                          placeholder="Ogranak"
                          name="branch"
                          value={this.state.branch}
                          onChange={this.onChange}
                          error={errors.branch}
                        />
                      </div>*/}
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <TextFieldGroup
                          label="Adresa"
                          placeholder="Adresa"
                          name="address"
                          value={this.state.address}
                          onChange={this.onChange}
                          error={errors.address}
                        />
                      </div>
                      <div className="col-md-2">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            'http://maps.google.com/?q=' +
                            this.state.address +
                            ', ' +
                            this.state.city
                          }
                        >
                          <br />
                          <span
                            className="btn-fill col-md-12 btn pull-center btn-info"
                            style={{ margin: '3px' }}
                          >
                            <i className="pe-7s-map-marker" />
                            {'  Pronadji na mapi '}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                          error={errors.city}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Telefon"
                          placeholder="Telefon"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          error={errors.phone}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Mobilni"
                          placeholder="Mobilni"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.onChange}
                          error={errors.mobile}
                        />
                      </div>
                    </div>
                    <div className="row">
                      {/*<div className="col-md-4">
                        <TextFieldGroup
                          label="model uredjaja"
                          placeholder="Model uredjaja"
                          name="devicemodel"
                          value={this.state.devicemodel}
                          onChange={this.onChange}
                          error={errors.devicemodel}
                        />
                      </div>*/}
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Serijski broj uredjaja"
                          placeholder="Serijski broj uredjaja"
                          name="ibfk"
                          value={this.state.ibfk}
                          error={errors.ibfk}
                          disabled="disabled"
                        />
                      </div>
                     {/*<div className="col-md-4">
                        <TextFieldGroup
                          label="identifikacioni broj modula"
                          placeholder="Identifikacioni broj modula"
                          name="ibfm"
                          value={this.state.ibfm}
                          onChange={this.onChange}
                          error={errors.ibfm}
                        />
                      </div>*/}
                    </div>
                    <div className="row">
                      {/*<div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Datum fiskalizacije:{' '}
                          {isEmpty(this.state.dateoffiscal) ? null : (
                            <span>
                              <Moment format="DD.MM.YYYY">
                                {this.state.dateoffiscal}
                              </Moment>
                            </span>
                          )}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6 className="form-control form-control-lg">
                          Vendor:{' '}
                          {isEmpty(this.state.vendor) ? null : (
                            <span>
                                {this.state.vendor}
                            </span>
                          )}
                        </h6>
                      </div>*/}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextAreaFieldGroup
                          label="Opis/komentar"
                          placeholder="Opis/Komentar"
                          name="description"
                          value={this.state.description}
                          onChange={this.onChange}
                          error={errors.description}
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-4">
                        <Link
                          to="/devices"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Uredjaje
                        </Link>
                      </div>
                      <div className="col-md-2">
                        <a href={'tel:' + this.state.phone}>
                          <span
                            className="btn-fill col-md-12 btn pull-center btn-success"
                            style={{ margin: '3px' }}
                          >
                            <i className="pe-7s-call" />
                            {' ' + this.state.phone}
                          </span>
                        </a>
                      </div>
                      <div className="col-md-2">
                        <a href={'tel:' + this.state.mobile}>
                          <span
                            className="btn-fill col-md-12 btn pull-center btn-success"
                            style={{ margin: '3px' }}
                          >
                            <i className="pe-7s-phone" />
                            {' ' + this.state.mobile}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                title="Tiketi"
                category="Lista tiketa"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <TicketHistory ticketlist={this.state.ticketlist} />
                  </div>
                }
              />
            </Col>
            {/*<Col md={4}>
              <Card
                title="Tehnicki pregledi"
                category="Lista ranije izvrsenih tehnickih pregleda"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <AnnualHistory annualservices={this.state.annualservices} />
                  </div>
                }
              />
            </Col>*/}
          </Row>
        </Container>
      );
    }

    return <div className="content"> {deviceContent}</div>;
  }
}

Device.propTypes = {
  getDeviceById: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  updateDevice: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  device: state.device,
  errors: state.errors,
  services: state.service,
  client: state.client
});

export default connect(
  mapStateToProps,
  { getDeviceById, getServices, getClients, updateDevice }
)(withRouter(Device));
