import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getServiceById, createService } from '../../actions/serviceActions';
import isEmpty from '../../validation/is-empty';
import { Card } from '../../components/Card/Card';
import TextFieldGroup from '../common/TextFieldGroup';

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      city: '',
      phone: '',
      email: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const editService = {
      name: this.state.name,
      address: this.state.address,
      city: this.state.city,
      phone: this.state.phone,
      email: this.state.email
    };

    this.props.createService(editService, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.service.service) {
      const service = nextProps.service.service;

      service.name = !isEmpty(service.name) ? service.name : '';
      service.address = !isEmpty(service.address) ? service.address : '';
      service.city = !isEmpty(service.city) ? service.city : '';
      service.phone = !isEmpty(service.phone) ? service.phone : '';
      service.email = !isEmpty(service.email) ? service.email : '';

      this.setState({
        name: service.name,
        address: service.address,
        city: service.city,
        phone: service.phone,
        email: service.email
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    if (this.props.match.params._id) {
      this.props.getServiceById(this.props.match.params._id);
    }
  }

  render() {
    const { errors } = this.state;
    const { service, loading } = this.props.service;
    let serviceContent;
    if (service === null || loading) {
      serviceContent = <Spinner />;
    } else {
      serviceContent = (
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title={this.state.name}
                category="Podaci servisa"
                content={
                  <form onSubmit={this.onSubmit}>
                    <TextFieldGroup
                      label="Ime Servisa"
                      placeholder="Ime Servisa"
                      name="name"
                      value={this.state.name}
                      error={errors.name}
                      disabled="disabled"
                    />
                    <TextFieldGroup
                      label="Email Adresa"
                      placeholder="Email Adresa"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      error={errors.email}
                    />
                    <TextFieldGroup
                      label="Adresa servisa"
                      placeholder="Adresa servisa"
                      name="address"
                      value={this.state.address}
                      onChange={this.onChange}
                      error={errors.address}
                    />
                    <TextFieldGroup
                      label="Grad"
                      placeholder="Grad"
                      name="city"
                      value={this.state.city}
                      onChange={this.onChange}
                      error={errors.city}
                    />
                    <TextFieldGroup
                      label="Telefon"
                      placeholder="Telefon servisa"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.onChange}
                      error={errors.phone}
                    />

                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/services"
                          className="btn-fill col-md-12 btn pull-center btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Servisi
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      );
    }

    return <div className="content">{serviceContent}</div>;
  }
}

Service.propTypes = {
  getServiceById: PropTypes.func.isRequired,
  createService: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  service: state.service,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getServiceById, createService }
)(Service);
