import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderLinks from '../Header/HeaderLinks.jsx';

import imagine from '../../assets/img/Screenshot_1.png';
import logo from '../../assets/img/Logo-Galeb-1.png';

import dashboardRoutes from '../../routes/dashboard.jsx';
import adminRoutes from '../../routes/admin.jsx';
import clientuserRoutes from '../../routes/clientuser.jsx';
import serviceRoutes from '../../routes/serviser.jsx';
import pmRoutes from '../../routes/pm.jsx';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: 'url(' + imagine + ')'
    };
    let route;
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'Admin') {
        route = adminRoutes;
      } else if (this.props.auth.user.position === 'Klijent') {
        route = clientuserRoutes;
      } else if (this.props.auth.user.position === 'Serviser') {
        route = serviceRoutes;
      } else if (this.props.auth.user.position === 'PM') {
        route = pmRoutes;
      } else {
        route = dashboardRoutes;
      }
    } else {
      route = [{ redirect: true, path: '/', to: '/login', name: 'Login' }];
    }

    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color="black"
        data-image={imagine}
      >
        
        <div className="sidebar-background" style={sidebarBackground} />
        
        <div className="logo">
          <a
            href="https://ticketing.efiskalizovan.rs"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </a>
          <a
            href="https://ticketing.efiskalizovan.rs"
            className="simple-text logo-normal"
            style={{ textDecoration: 'none' }}
          >
            Galeb Ticketing
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <HeaderLinks /> : null}
            {route.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? 'active active-pro'
                        : this.activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <span>{prop.name}</span>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Sidebar);
