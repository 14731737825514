import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import clientReducer from './clientReducer';
import serviceReducer from './serviceReducer';
import deviceReducer from './deviceReducer';
import ticketReducer from './ticketReducer';
import requestReducer from './requestReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  client: clientReducer,
  service: serviceReducer,
  device: deviceReducer,
  ticket: ticketReducer,
  request: requestReducer
});
