import {
  TICKETS_LOADING,
  GET_TICKETS,
  GET_TICKET,
  DELETE_TICKET,
  GET_TICKETS_BYUSER
} from '../actions/types';

const initialState = {
  ticketsByUser: [],
  tickets: [],
  ticket: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_BYUSER:
      return {
        ...state,
        ticketsByUser: action.payload,
        loading: false
      }
    case TICKETS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
        loading: false
      };
    case GET_TICKET:
      return {
        ...state,
        ticket: action.payload,
        loading: false
      };
    case DELETE_TICKET:
      return {
        ...state,
        tickets: state.tickets.filter(ticket => ticket._id !== action.payload)
      };
    default:
      return state;
  }
}
