import axios from 'axios';
import { get } from 'mongoose';
import {
  GET_ERRORS,
  DEVICES_LOADING,
  GET_DEVICES,
  GET_DEVICES_COUNT,
  DELETE_DEVICE,
  GET_DEVICE,
  GET_DELETED_DEVICES,
  GET_SEARCH_DEVICES,
  GET_CLIENTS_DEVICES,
} from './types';

// Create Device
export const createDevice = (deviceData, history) => dispatch => {
  axios
    .post('/api/devices/create', deviceData)
    .then(res => history.push('/devices'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const getDeviceSearch = search => dispatch => {
  axios
    .get(`/api/devices/${search}`)
    .then(res => 
      dispatch({
        type: GET_SEARCH_DEVICES,
        payload: res.data
      })
      )
      .catch(err =>
        dispatch({
          type: GET_SEARCH_DEVICES,
          payload: null
        })
      );
};

// Annual Service
export const annualDevice = (deviceData, history) => dispatch => {
  axios
    .post('/api/devices/annual', deviceData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//get clients devices for add-ticket page
export const getClientsDevices = (clientsId) => dispatch => {
  dispatch(setDevicesLoading());
  axios
    .get(`/api/devices/getClientsDevices/${clientsId}`)
    .then(res =>
      dispatch({
        type: GET_CLIENTS_DEVICES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENTS_DEVICES,
        payload: null
      })
    );
}

// Get all devices
export const getDevices = () => dispatch => {
  dispatch(setDevicesLoading());
  axios
    .get('/api/devices/all')
    .then(res =>
      dispatch({
        type: GET_DEVICES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DEVICES,
        payload: null
      })
    );
};

// get devices count
export const getDevicesCount = () => dispatch =>{
  dispatch(setDevicesLoading())
  axios
    .get('/api/devices/getDevicesCount')
    .then(res =>
      dispatch({
        type: GET_DEVICES_COUNT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DEVICES_COUNT,
        payload: null
  })
  );
};

// Get all deleted devices
export const getDeletedDevices = () => dispatch => {
  dispatch(setDevicesLoading());
  axios
    .get('/api/devices/deleted')
    .then(res =>
      dispatch({
        type: GET_DELETED_DEVICES,
        payload: res.data
      })
    )
    .catch(err => dispatch({ type: GET_DELETED_DEVICES, payload: null }));
};

// Get all devices by client
export const getDevicesByClient = (deviceData, history) => dispatch => {
  dispatch(setDevicesLoading());
  axios
    .post('/api/devices/devicesbyclient', deviceData)
    .then(res =>
      dispatch({
        type: GET_DEVICES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DEVICES,
        payload: null
      })
    );
};

// Get device by id
export const getDeviceById = _id => dispatch => {
  dispatch(setDevicesLoading());
  axios
    .get(`/api/devices/device/${_id}`)
    .then(res =>
      dispatch({
        type: GET_DEVICE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DEVICE,
        payload: null
      })
    );
};
// Create Profile
export const updateDevice = (dev_id, deviceData, history) => dispatch => {
  axios
    .post(`/api/devices/update/${dev_id}`, deviceData)
    .then(res => history.push('/devices'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Delete Device
export const deleteDevice = id => dispatch => {
  axios
    .delete(`/api/devices/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_DEVICE,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Activate Device
export const activateDevice = id => dispatch => {
  axios
    .post(`/api/devices/activate/${id}`)
    .then()
    .catch(serviceerr =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

// Devices loading
export const setDevicesLoading = () => {
  return {
    type: DEVICES_LOADING
  };
};
