// import Dashboard from "views/Dashboard/Dashboard";
// import UserProfile from "views/UserProfile/UserProfile";
// import TableList from "views/TableList/TableList";
// import Typography from "views/Typography/Typography";
// import Icons from "views/Icons/Icons";
// import Maps from "views/Maps/Maps";
// import Notifications from "views/Notifications/Notifications";
// import Upgrade from "views/Upgrade/Upgrade";

const clientuserRoutes = [
  { path: '/dashboardclient', name: 'Dashboard', icon: 'pe-7s-graph' },
  { path: '/ticketsclient', name: 'Tiketi ', icon: 'pe-7s-note2' },
  { path: '/profile-client', name: 'Profil', icon: 'pe-7s-user' },
  //{ path: '/requestsclient', name: 'Ostali Zahtevi', icon: 'pe-7s-note2' },
  { redirect: true, path: '/', to: '/login', name: 'Login' }
];

export default clientuserRoutes;
