import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextFieldGroup from '../common/TextFieldGroup';
import SelectListGroup from '../common/SelectListGroup';
import { createProfile } from '../../actions/profileAction';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card';

class CreateProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      phone: '',
      branch: '',
      service: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const profileData = {
      phone: this.state.phone,
      branch: this.state.branch,
      service: this.state.service
    };

    this.props.createProfile(profileData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    const { services } = this.props.services;
    //Select options for Service

    const options = services.map(service => ({
      label: service.name,
      value: service._id
    }));
    options.unshift({ label: '* Selektujte servis', value: '' });
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title="Kreiraj profil"
                category="Dodaj osnovne podatke"
                content={
                  <form onSubmit={this.onSubmit}>
                    <TextFieldGroup
                      placeholder="* Telefon"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.onChange}
                      error={errors.phone}
                      label="Telefon"
                    />
                    <SelectListGroup
                      placeholder="Servis"
                      name="service"
                      value={this.state.service}
                      onChange={this.onChange}
                      options={options}
                      error={errors.service}
                      labels="Servis"
                    />
                    <TextFieldGroup
                      placeholder="Ogranak"
                      name="branch"
                      value={this.state.branch}
                      onChange={this.onChange}
                      error={errors.branch}
                      label="Adresa - Ogranak"
                    />
                    <input
                      type="submit"
                      value="Sacuvaj"
                      className="btn btn-info btn-fill btn-block mt-4"
                    />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

CreateProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors,
  services: state.service
});

export default connect(
  mapStateToProps,
  { createProfile }
)(withRouter(CreateProfile));
