import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profileAction';
import { getClients, getClientsCount } from '../../actions/clientActions';
import { getServices } from '../../actions/serviceActions';
import { getDevices, getDevicesCount } from '../../actions/deviceActions';
import { getTickets, getTicketsSC, getTicketsUID } from '../../actions/ticketActions';
import Spinner from '../common/Spinner';
import { Link } from 'react-router-dom';
import { StatsCard } from '../StatsCard/StatsCard';
import TicketsDashboard from '../tickets/TicketsDashboard';

class Dashboard extends Component {
  componentDidMount() {
    if (this.props.auth.user.position === 'Klijent') {
      this.props.history.push('/dashboardclient');
    } else if (this.props.auth.user.position === 'PM'){
      this.props.getCurrentProfile();
      this.props.getClientsCount();
      this.props.getServices();
      this.props.getDevicesCount();
      //this.props.getTickets();
      this.props.getTicketsSC(this.props.auth.user.service);
    } else if (this.props.auth.user.position === 'Serviser'){
      this.props.getCurrentProfile();
      this.props.getClientsCount();
      this.props.getServices();
      this.props.getDevicesCount();
      //this.props.getTickets();
      this.props.getTicketsUID(this.props.auth.user.id);
    } else {
      this.props.getCurrentProfile();
      this.props.getClientsCount();
      this.props.getServices();
      this.props.getDevicesCount();
      this.props.getTickets();
    }
  }
  
  render() {
    const { devicesCount } = this.props.device;
    const { clientsCount } = this.props.client;
    const { user } = this.props.auth;
    const { profile } = this.props.profile;
    //const { clients } = this.props.client;
    const { services } = this.props.service;
    const { loading } = this.props.device;
    const loadingtickets = this.props.ticket.loading;
    
    let dashboardContent;
    if (profile === null || loading) {
      dashboardContent = <Spinner />;
    } else {
      if (Object.keys(profile).length > 4) {
        dashboardContent = (
          <Container fluid>
            <Row>
              <Col lg={3} sm={6}>
                <Link
                  to="/devices"
                  style={{ textDecoration: 'none', color: 'black' }}
                  >
                  <StatsCard
                    bigIcon={<i className="pe-7s-usb text-warning" />}
                    statsText="Uredjaji"
                    statsValue={devicesCount}
                    statsIcon={<i className="fa fa-refresh" />}
                    statsIconText="Trenutno u bazi"
                  />
                </Link>
              </Col>
              <Col lg={3} sm={6}>
                <Link
                  to="/clients"
                  style={{ textDecoration: 'none', color: 'black' }}
                  >
                  <StatsCard
                    bigIcon={<i className="pe-7s-users text-success" />}
                    statsText="Klijenti"
                    statsValue={clientsCount}
                    statsIcon={<i className="fa fa-calendar-o" />}
                    statsIconText="Ukupno u bazi"
                  />
                </Link>
              </Col>
              <Col lg={3} sm={6}>
                <Link
                    to="/services"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                  <StatsCard
                    bigIcon={<i className="pe-7s-tools text-danger" />}
                    statsText="Servisi"
                    statsValue={services.length}
                    statsIcon={<i className="fa fa-refresh" />}
                    statsIconText="Broj aktivnih servisa"
                  />
                </Link>
              </Col>
            </Row>
            <Row>
              {!loadingtickets ? (
                <TicketsDashboard ticket={this.props.ticket} />
              ) : (
                <Spinner />
              )}
            </Row>
          </Container>
        );
      } else { 
        dashboardContent = (
          <div>
            <p className="lead text-muted">Dobrodosli, {user.name}</p>
            <p>
              Jos uvek nemate setovan profil, molimo dodajte neophodne
              informacije kako bi nastavili.
            </p>
            <Link
              to="/edit-profile/create-profile"
              className="btn btn-fill btn-lg btn-info"
            >
              Kreiraj profil
            </Link>
          </div>
        );
      }
    }

    return <div className="content">{dashboardContent}</div>;
  }
}

Dashboard.propTypes = {
  getDevicesCount: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getDevices: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  getClientsCount: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
  getTicketsSC: PropTypes.func.isRequired,
  getTicketsUID: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  clientsCount: state.clientsCount,
  client: state.client,
  service: state.service,
  device: state.device,
  devicesCount: state.devicesCount,
  ticket: state.ticket
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, getClients, getServices, getDevices, getTickets, getTicketsSC, getTicketsUID, getClientsCount, getDevicesCount }
)(Dashboard);
