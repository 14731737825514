import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import Moment from 'react-moment';
import { deleteTicket } from '../../actions/ticketActions';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Card from '../../components/Card/Card';
import { StatsCard } from '../StatsCard/StatsCard';
import { Bar, Pie } from 'react-chartjs-2';
import NotificationSystem from 'react-notification-system';
import { style } from '../../variables/Variables.jsx';
import soundfile from '../../assets/sound.mp3';
import Sound from 'react-sound';
import Pagination from '../common/Pagination';
import { confirmAlert } from 'react-confirm-alert';

class TicketsDashboard extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.state = {
      search: '',
      _notificationSystem: null,
      alltickets: props.ticket.tickets.filter(tickets => {
        return tickets.status < 4;
      }),
      currentTickets: [],
      currentPage: null,
      totalPages: null
    };
  }

  onPageChanged = data => {
    const { alltickets } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTickets = alltickets.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentTickets, totalPages });
  };

  componentDidMount() {
    if (this.props.ticket.tickets.length > 0) {
      this.setState({ _notificationSystem: this.refs.notificationSystem });
      var _notificationSystem = this.refs.notificationSystem;
      var level = 'error';
      // _notificationSystem.addNotification({
      //   title: <span data-notify="icon" className="pe-7s-gift" />,
      //   message: (
      //     <div>
      //       Dobrodosli na <b>Galeb Ticketing System</b> - evidencija tehnickih
      //       pregleda
      //     </div>
      //   ),
      //   level: 'info',
      //   position: 'tr',
      //   autoDismiss: 15
      // });
      _notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-bell" />,
        message: (
          <div>
            {/*<Sound
              url={soundfile}
              playStatus={Sound.status.PLAYING}
              onLoading={this.handleSongLoading}
              onPlaying={this.handleSongPlaying}
              onFinishedPlaying={this.handleSongFinishedPlaying}
            />*/}
            <b>
              Trenutno urgentih tiketa:{' '}
              {
                this.props.ticket.tickets.filter(ticket => {
                  return ticket.status < 4 && ticket.priority === 4;
                }).length
              }
            </b>
          </div>
        ),
        level: level,
        position: 'tr',
        autoDismiss: 15
      });
      _notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-tools" />,
        message: (
          <div>
            <b>
              Trenutno aktivnih tiketa:{' '}
              {
                this.props.ticket.tickets.filter(ticket => {
                  return ticket.status < 4;
                }).length
              }
            </b>
          </div>
        ),
        level: 'warning',
        position: 'tr',
        autoDismiss: 15
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.ticket.tickets.length === 0 &&
      nextProps.ticket.tickets.length > 0
    ) {
      this.setState({ _notificationSystem: this.refs.notificationSystem });
      var _notificationSystem = this.refs.notificationSystem;
      var level = 'error';
      _notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-gift" />,
        message: (
          <div>
            Dobrodosli na <b>Galeb Ticketing System</b> - evidencija tehnickih
            pregleda
          </div>
        ),
        level: 'info',
        position: 'tr',
        autoDismiss: 15
      });
      _notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-bell" />,
        message: (
          <div>
            <Sound
              url={soundfile}
              playStatus={Sound.status.PLAYING}
              onLoading={this.handleSongLoading}
              onPlaying={this.handleSongPlaying}
              onFinishedPlaying={this.handleSongFinishedPlaying}
            />
            <b>
              Trenutno urgentih tiketa:{' '}
              {
                nextProps.ticket.tickets.filter(ticket => {
                  return ticket.status === 1 && ticket.priority === 4;
                }).length
              }
            </b>
          </div>
        ),
        level: level,
        position: 'tr',
        autoDismiss: 15
      });
      _notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-tools" />,
        message: (
          <div>
            <b>
              Trenutno aktivnih tiketa:{' '}
              {
                nextProps.ticket.tickets.filter(ticket => {
                  return ticket.status < 4;
                }).length
              }
            </b>
          </div>
        ),
        level: 'warning',
        position: 'tr',
        autoDismiss: 15
      });
    }
  }

  updateSearch(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }
 
  onDeleteClick(id) {
    const newTick = {
      priority: this.props.priority,
      status: this.props.status,
      service: this.props.service,
      servicer: this.props.servicer,
      user: this.props.user,
      desc: this.props.desc,
      device: this.props.device,
      parts: this.props.parts
    };
    confirmAlert({
      title: 'Otkazi tiket',
      message: 'Da li sigurno zelite da otkazete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => ((this.props.deleteTicket(id, newTick), window.location.reload()))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const { loading } = this.props.ticket;
    const { alltickets, currentTickets } = this.state;
    let styledelete;

    if (this.props.auth.user.position === 'Admin') {
      styledelete = { width: '80px' };
    } else {
      styledelete = { width: '80px', visibility: 'hidden' };
    }
    console.log(styledelete);
    let aktivniTiketi = 0;
    let utokuTiketi = 0;
    let nacekanjuTiketi = 0;
    let urgentniTiketi = 0;
    let otkazaniTiketi = 0;
    let zavrseniTiketi = 0;

    const tickets = this.props.ticket.tickets.filter(ticket => {
      return ticket.status < 4 && ticket.priority === 4
        ? ticket.status === 2
          ? ((aktivniTiketi += 1),
            (urgentniTiketi += 1),
            (nacekanjuTiketi += 1))
          : ticket.status === 3
          ? ((aktivniTiketi += 1), (urgentniTiketi += 1), (utokuTiketi += 1))
          : ((aktivniTiketi += 1), (urgentniTiketi += 1))
        : ticket.status < 4 && ticket.status === 2
        ? ((nacekanjuTiketi += 1), (aktivniTiketi += 1))
        : ticket.status < 4 && ticket.status === 3
        ? ((utokuTiketi += 1), (aktivniTiketi += 1))
        : ticket.status < 4
        ? (aktivniTiketi += 1)
        : ticket.status === 4
        ? (zavrseniTiketi += 1)
        : ticket.status === 5
        ? (otkazaniTiketi += 1)
        : null;
    });

    let ticketItems;
    if (tickets === null || loading) {
      ticketItems = (
        <tr>
          <td />
          <td />
          <td />
          <td>
            <Spinner />
          </td>
          <td />
          <td />
          <td />
        </tr>
      );
    } else {
      if (tickets.length > 0) {
        ticketItems = currentTickets.map(ticket => {
          return ticket.status < 4 ? (
            <tr key={ticket._id}>
              <td>
                {isEmpty(ticket.device) ? 'Nije u bazi' : ticket.device.ibfk}
              </td>
              <td>
                {(() => {
                  switch (ticket.priority) {
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Low
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Medium
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          High
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Urgent
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                {isEmpty(ticket.device) ? '' : ticket.device.address},{' '}
                {isEmpty(ticket.device) ? '' : ticket.device.city}
              </td>
              <td>
                {isEmpty(ticket.device) ? '' : ticket.service.name}
              </td>
              <td>{ticket.desc}</td>
              <td>
                {(() => {
                  switch (ticket.status) {
                    case 5:
                      return (
                        <span className="btn-fill btn btn-sm btn-danger">
                          Otkazan
                        </span>
                      );
                    case 1:
                      return (
                        <span className="btn-fill btn btn-sm btn-info">
                          Novi
                        </span>
                      );
                    case 2:
                      return (
                        <span className="btn-fill btn btn-sm btn-default">
                          Na cekanju
                        </span>
                      );
                    case 3:
                      return (
                        <span className="btn-fill btn btn-sm btn-warning">
                          U toku
                        </span>
                      );
                    case 4:
                      return (
                        <span className="btn-fill btn btn-sm btn-success">
                          Zavrsen
                        </span>
                      );
                    default:
                      return null;
                  }
                })()}
              </td>
              <td>
                <Moment format="DD.MM.YYYY">{ticket.datecreate}</Moment>
              </td>
              <td>
                {isEmpty(ticket.dateend) ? (
                  ''
                ) : (
                  <Moment format="DD.MM.YYYY">{ticket.dateend}</Moment>
                )}
              </td>
              <td>{isEmpty(ticket.parts) ? '' : ticket.parts}</td>
              <td >{isEmpty(ticket.user) ? '' : ticket.user.name}</td>
              <td>
                <Link
                  to={`/tickets/${ticket._id}`}
                  style={{ marginRight: '3px', width: '80px' }}
                  className="btn-fill btn btn-sm btn-info">
                  Izmeni
                </Link>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-fill btn btn-sm btn-danger"
                  onClick={this.onDeleteClick.bind(this, ticket._id)}>
                  Otkazi
                </button>
              </td>
            </tr>
          ) : null;
        });
      } else {
        ticketItems = (
          <tr>
            <td>Nijedan tiket nije pronadjen...</td>
          </tr>
        );
      }
    }

    let labels = [
      'Aktivni: ' + aktivniTiketi,
      'Otkazani: ' + otkazaniTiketi,
      'Zavrseni: ' + zavrseniTiketi
    ];
    let series = [aktivniTiketi, otkazaniTiketi, zavrseniTiketi];
    let labelsBar = [
      'Aktivni: ' + aktivniTiketi,
      'Otkazani: ' + otkazaniTiketi,
      'Zavrseni: ' + zavrseniTiketi,
      'U toku: ' + utokuTiketi,
      'Urgentni: ' + urgentniTiketi,
      'Na cekanju: ' + nacekanjuTiketi
    ];
    let seriesBar = [
      aktivniTiketi,
      otkazaniTiketi,
      zavrseniTiketi,
      utokuTiketi,
      urgentniTiketi,
      nacekanjuTiketi
    ];
    const datajs = {
      labels: labelsBar,
      datasets: [
        {
          label: 'Tiketi po statusu',
          fill: false,
          lineTension: 0.1,
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 99, 132, 0.8)',
            'rgba(0, 204, 102, 0.8)',
            'rgba(0, 204, 204,0.8)',
            'rgba(255, 0, 0,0.8)',
            'rgba(255, 206, 86,0.8)'
          ],
          borderColor: 'rgba(51, 153, 255,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(51, 153, 255,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(51, 153, 255,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: seriesBar
        }
      ]
    };
    const datajsPie = {
      labels: labels,
      datasets: [
        {
          data: series,
          backgroundColor: ['#36A2EB', '#FF6384', '#00cc66'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384', '#00cc66']
        }
      ]
    };

    return (
      <Container fluid>
        <NotificationSystem ref="notificationSystem" style={style} />
        <Row>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-tools text-info" />}
              statsText="Aktivni"
              statsValue={aktivniTiketi}
              statsIcon={<i className="fa fa-wrench" />}
              statsIconText="Trenutno aktivnih tiketa"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-bell text-danger" />}
              statsText="Urgentni"
              statsValue={urgentniTiketi}
              statsIcon={<i className="fa fa-bell-o" />}
              statsIconText="Trenutno urgentnih tiketa"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-clock text-success" />}
              statsText="U toku"
              statsValue={utokuTiketi}
              statsIcon={<i className="fa fa-clock-o" />}
              statsIconText="Broj tiketa koji su u toku"
            />
          </Col>
          <Col lg={3} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-coffee text-warning" />}
              statsText="Na cekanju"
              statsValue={nacekanjuTiketi}
              statsIcon={<i className="fa fa-coffee" />}
              statsIconText="Broj tiketa na cekanju"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title="Lista aktivnih tiketa"
              category="Tiketi koji su novi ili u toku"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Uredjaj</th>
                      <th>Prioritet</th>
                      <th>Adresa</th>
                      <th>Servis</th>
                      <th>opis kvara</th>
                      <th>Status</th>
                      <th>datum kreiranja</th>
                      <th>datum izmene</th>
                      <th>opis popravke</th>
                      <th>korisnik</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>{ticketItems}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>
        <Row>
        <div className="pull-right" style={{ marginRight: '5px' }}>
              <Pagination
                totalRecords={alltickets.length}
                pageLimit={10}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </div>
        </Row>
        <Row>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-close-circle text-danger" />}
              statsText="Otkazani tiketi"
              statsValue={otkazaniTiketi}
              statsIcon={<i className="fa fa-times-circle-o" />}
              statsIconText="Broj otkazanih tiketa"
            />
          </Col>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-like2 text-success" />}
              statsText="Zavrseni tiketi"
              statsValue={zavrseniTiketi}
              statsIcon={<i className="fa fa-thumbs-up" />}
              statsIconText="Broj zavrsenih tiketa"
            />
          </Col>
          <Col lg={4} sm={6}>
            <StatsCard
              bigIcon={<i className="pe-7s-note2 text-info" />}
              statsText="Ukupno zatvorenih"
              statsValue={zavrseniTiketi + otkazaniTiketi}
              statsIcon={<i className="fa fa-thumbs-up" />}
              statsIconText="Broj zatvorenih tiketa (zavrseni i otkazani)"
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Card
              id="chartActivity"
              title="Graficki pregled tiketa"
              category="Pregled tiketa po statusu"
              stats="Trenutni podaci"
              statsIcon="fa fa-history"
              content={
                <div>
                  {/* <ChartistGraph
                  data={dataBar}
                  
                  type='Line'
                  options={optionsBar}
                  responsiveOptions={responsiveBar}
                /> */}
                  <Bar
                    ref="chart"
                    data={datajs}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </div>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              title="Statistika tiketa"
              category="Podela tiketa po izvrsenju"
              stats="Trenutni podaci"
              statsIcon="fa fa-clock-o"
              content={
                <div>
                  <Pie
                    data={datajsPie}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                  {/* <ChartistGraph data={dataPie} type='Pie' /> */}
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

TicketsDashboard.propTypes = {
  deleteTicket: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deleteTicket }
)(TicketsDashboard);
