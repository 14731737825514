import {
  GET_DEVICES,
  DEVICES_LOADING,
  DELETE_DEVICE,
  GET_DEVICE,
  GET_DELETED_DEVICES,
  GET_DEVICES_COUNT,
  GET_SEARCH_DEVICES,
  GET_CLIENTS_DEVICES
} from '../actions/types';

const initialState = {
  clientsDevices: [],
  devices: [],
  devicesCount: [],
  deleteddevices: [],
  deviceSearch: [],
  device: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_DEVICES:
      return {
        ...state,
        clientsDevices: action.payload,
        loading:false,
      }
    case DEVICES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DEVICES:
      return {
        ...state,
        devices: action.payload,
        loading: false
      };
    case GET_SEARCH_DEVICES:
      return {
        ...state,
        deviceSearch: action.payload,
        loading: false
      };
    case GET_DEVICES_COUNT:
      return {
        ...state,
        devicesCount: action.payload,
        loading: false
      };
    case GET_DEVICE:
      return {
        ...state,
        device: action.payload,
        loading: false
      };
    case DELETE_DEVICE:
      return {
        ...state,
        devices: state.devices.filter(device => device._id !== action.payload)
      };
    case GET_DELETED_DEVICES:
      return {
        ...state,
        deleteddevices: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
